import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoSoLuuTruTour } from 'src/app/shared/CoSoLuuTruTour.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class CoSoLuuTruTourService extends BaseService{
    displayColumns: string[] = ['Name', 'NgayBatDau', 'NgayKetThuc', 'GiaCaoNhat', 'GiaThapNhat', 'Active', 'Save']; 
    list: CoSoLuuTruTour[] | undefined;
    listSearch: CoSoLuuTruTour[] | undefined;
    listSearch001: CoSoLuuTruTour[] | undefined;
    formData!: CoSoLuuTruTour;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "CoSoLuuTruTour";
    }
}

