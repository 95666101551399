import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DiaDiemDaChieu } from 'src/app/shared/DiaDiemDaChieu.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DiaDiemDaChieuService extends BaseService{
    displayColumns: string[] = ['Code', 'Name']; 
    list: DiaDiemDaChieu[] | undefined;
    listSearch: DiaDiemDaChieu[] | undefined;
    listSearch001: DiaDiemDaChieu[] | undefined;
    formData!: DiaDiemDaChieu;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DiaDiemDaChieu";
    }
    GetByDanhMucQuanHuyenIDAndEmptyToListAsync(danhMucQuanHuyenID: number) {
        let url = this.aPIURL + this.controller + '/GetByDanhMucQuanHuyenIDAndEmptyToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('danhMucQuanHuyenID', JSON.stringify(danhMucQuanHuyenID));
        return this.httpClient.post(url, formUpload);
    }
}

