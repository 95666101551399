import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-co-so-luu-tru-su-kien',
  templateUrl: './co-so-luu-tru-su-kien.component.html',
  styleUrls: ['./co-so-luu-tru-su-kien.component.css']
})
export class CoSoLuuTruSuKienComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
