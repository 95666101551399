<div class="row">
    <div id="breadcrumbs-wrapper" data-image="https://cms.ctacorporation.vn/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Discover 360 <span
                            *ngIf="DiaDiemDaChieuService.list">({{DiaDiemDaChieuService.list.length}} items)</span>
                    </h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">Tourists</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">Discover 360
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section section-data-tables">
                <div class="card">
                    <div class="card-content">
                        <div class="col s9 m9 l9">
                            <input matInput [(ngModel)]="searchString" placeholder="Search ..."
                                class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                (keyup.enter)="onSearch()">
                        </div>
                        <div class="col s3 m3 l3">
                            <a title="Search" (click)="onSearch()"
                                class="btn-floating waves-effect waves-light green darken-1"><i
                                    class="material-icons">search</i></a>&nbsp;&nbsp;&nbsp;
                        </div>
                        <div class="KhungOverflow">
                            <mat-table [dataSource]="dataSource" matSort>
                                <ng-container matColumnDef="Code">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Image
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <a target="_blank" href="{{element.Code}}" *ngIf="element.Code"
                                            title="{{element.Code}}">
                                            <img class="responsive-img" width="30%" title="{{element.Code}}"
                                                alt="{{element.Code}}" [src]="element.Code"></a>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Location
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <a title="{{element.Note}}" target="_blank"
                                            href="{{element.Note}}">{{element.Name}}</a>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="loading">
                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                        Đang tải dữ liệu...
                                    </mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="noData">
                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                        Chưa có dữ liệu.
                                    </mat-footer-cell>
                                </ng-container>
                                <mat-header-row
                                    *matHeaderRowDef="DiaDiemDaChieuService.displayColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: DiaDiemDaChieuService.displayColumns;"></mat-row>
                                <mat-footer-row *matFooterRowDef="['loading']"
                                    [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                                <mat-footer-row *matFooterRowDef="['noData']"
                                    [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}">
                                </mat-footer-row>
                            </mat-table>
                            <!-- <mat-paginator [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                [showFirstLastButtons]></mat-paginator> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>