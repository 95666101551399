import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThongBao } from 'src/app/shared/ThongBao.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThongBaoService extends BaseService {
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThongBao";
    }
    GetByThanhVienIDToListAsync() {
        let thanhVienIDString = localStorage.getItem(environment.ThanhVienID);
        if (thanhVienIDString) {
            let thanhVienID = Number(thanhVienIDString);
            let url = this.aPIURL + this.controller + '/GetByThanhVienIDToListAsync';
            const formUpload: FormData = new FormData();
            formUpload.append('thanhVienID', JSON.stringify(thanhVienID));
            return this.httpClient.post(url, formUpload);
        }
    }
}

