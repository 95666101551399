<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <div class="col s12 m12 l9">
        <h5 class="card-title"><b>Sự Kiện</b></h5>
    </div>
    <div class="col s12 m12 l3">
        <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
        <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
            <i class="material-icons">close</i>
        </a>
    </div>
    <input name="ID" [(ngModel)]="CoSoLuuTruSuKienService.formData.ID" type="hidden">
    <input name="ParentID" [(ngModel)]="CoSoLuuTruSuKienService.formData.ParentID" type="hidden">
    <input name="SortOrder" [(ngModel)]="CoSoLuuTruSuKienService.formData.SortOrder" type="hidden">
    <div class="col s12 m12 l6">
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Sự Kiện</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Sự Kiện" name="Name" [(ngModel)]="CoSoLuuTruSuKienService.formData.Name"
                    type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Note">Tình trạng</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <p>
                    <label>
                        <input type="checkbox" class="filled-in" name="Active"
                            [(ngModel)]="CoSoLuuTruSuKienService.formData.Active" />
                        <span>Phê duyệt / Chưa phê duyệt</span>
                    </label>
                </p>
            </div>
        </div>
    </div>
    <div class="col s12 m12 l6">
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Bắt đầu</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input name="NgayBatDau" style="text-align: right; font-weight: bold; width: 100%;"
                    [ngModel]="CoSoLuuTruSuKienService.formData.NgayBatDau | date:'yyyy-MM-dd'"
                    (ngModelChange)="NgayBatDauChange($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                    type="date">

            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Kết thúc</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input name="NgayKetThuc" style="text-align: right; font-weight: bold; width: 100%;"
                    [ngModel]="CoSoLuuTruSuKienService.formData.NgayKetThuc | date:'yyyy-MM-dd'"
                    (ngModelChange)="NgayKetThucChange($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                    type="date">

            </div>
        </div>
    </div>
    
    <div class="col s12 m12 l12">
        <ck-editor [config]="{'height': 400}" name="Note" [(ngModel)]="CoSoLuuTruSuKienService.formData.Note"
            skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
    </div>

    <div class="section" *ngIf="ID>0">
        <div class="row">
            <div class="col s12 m12 l12">
                <div class="section section-data-tables">
                    <div class="card">
                        <div class="card-content">
                            <div class="KhungOverflow">
                                <mat-table [dataSource]="PheDuyetDataSource" matSort #PheDuyetSort="matSort">
                                    <ng-container matColumnDef="NguoiYeuCauID">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Người yêu cầu
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <mat-select
                                                style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                name="NguoiYeuCauID{{element.ID}}"
                                                class="browser-default"
                                                [(ngModel)]="element.NguoiYeuCauID">
                                                <mat-option *ngFor="let item of ThanhVienService.list"
                                                    [value]="item.ID">
                                                    {{item.HoTen}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="CoQuanQuanLyNguoiYeuCauID">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <mat-select
                                                style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                name="CoQuanQuanLyNguoiYeuCauID{{element.ID}}"
                                                class="browser-default"
                                                [(ngModel)]="element.CoQuanQuanLyNguoiYeuCauID">
                                                <mat-option *ngFor="let item of CoQuanQuanLyService.list"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NoiDungYeuCau">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Nội dung
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <input placeholder="Nội dung yêu cầu"
                                                name="NoiDungYeuCau{{element.ID}}"
                                                [(ngModel)]="element.NoiDungYeuCau" type="text">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NgayYeuCau">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Ngày yêu cầu
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <input placeholder="Ngày yêu cầu" name="NgayYeuCau{{element.ID}}"
                                                [(ngModel)]="element.NgayYeuCau"
                                                 type="date">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NguoiPheDuyetID">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Người phê duyệt
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <mat-select
                                                style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                name="NguoiPheDuyetID{{element.ID}}"
                                                class="browser-default"
                                                [(ngModel)]="element.NguoiPheDuyetID">
                                                <mat-option *ngFor="let item of ThanhVienService.list"
                                                    [value]="item.ID">
                                                    {{item.HoTen}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="CoQuanQuanLyNguoiPheDuyetID">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <mat-select
                                                style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                name="CoQuanQuanLyNguoiPheDuyetID{{element.ID}}"
                                                class="browser-default"
                                                [(ngModel)]="element.CoQuanQuanLyNguoiPheDuyetID">
                                                <mat-option *ngFor="let item of CoQuanQuanLyService.list"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NoiDungPheDuyet">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Nội dung
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <input placeholder="Nội dung phê duyệt"
                                                name="NoiDungPheDuyet{{element.ID}}"
                                                [(ngModel)]="element.NoiDungPheDuyet" type="text">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="NgayPheDuyet">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Ngày phê duyệt
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <input placeholder="Ngày phê duyệt"
                                                name="NgayPheDuyet{{element.ID}}"
                                                [(ngModel)]="element.NgayPheDuyet" type="date">
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="DanhMucPheDuyetID">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>Loại phê duyệt
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <mat-select
                                                style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                name="DanhMucPheDuyetID{{element.ID}}"
                                                class="browser-default"
                                                [(ngModel)]="element.DanhMucPheDuyetID">
                                                <mat-option *ngFor="let item of DanhMucPheDuyetService.list"
                                                    [value]="item.ID">
                                                    {{item.Name}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="Save">
                                        <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        </mat-header-cell>
                                        <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                            <a title="Lưu thay đổi" (click)="PheDuyetSave(element)"
                                                class="btn-floating waves-effect waves-light cyan">
                                                <i class="material-icons">save</i>
                                            </a>&nbsp;&nbsp;&nbsp;
                                            <a style="padding-left: 8px;" (click)="PheDuyetDelete(element)"
                                                *ngIf="element.ID>0"
                                                class="btn-floating waves-effect waves-light red">
                                                <i class="material-icons">delete_outline</i>
                                            </a>
                                        </mat-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="loading">
                                        <mat-footer-cell *matFooterCellDef colspan="4">
                                            Đang tải dữ liệu...
                                        </mat-footer-cell>
                                    </ng-container>
                                    <ng-container matColumnDef="noData">
                                        <mat-footer-cell *matFooterCellDef colspan="4">
                                            Chưa có dữ liệu.
                                        </mat-footer-cell>
                                    </ng-container>
                                    <mat-header-row
                                        *matHeaderRowDef="PheDuyetService.displayColumns"></mat-header-row>
                                    <mat-row
                                        *matRowDef="let row; columns: PheDuyetService.displayColumns;"></mat-row>
                                    <mat-footer-row *matFooterRowDef="['loading']"
                                        [ngClass]="{'hide':PheDuyetDataSource!=null}"></mat-footer-row>
                                    <mat-footer-row *matFooterRowDef="['noData']"
                                        [ngClass]="{'hide':!(PheDuyetDataSource!=null && PheDuyetDataSource.data.length==0)}">
                                    </mat-footer-row>
                                </mat-table>
                                <mat-paginator #PheDuyetPaginator="matPaginator"
                                    [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                    [showFirstLastButtons]></mat-paginator>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>