import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NotificationService } from 'src/app/shared/Notification.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';


@Component({
  selector: 'app-thanh-vien-quen-mat-khau',
  templateUrl: './thanh-vien-quen-mat-khau.component.html',
  styleUrls: ['./thanh-vien-quen-mat-khau.component.css']
})
export class ThanhVienQuenMatKhauComponent implements OnInit {

  isShowLoading: boolean = false;
  queryString: string = environment.InitializationString;
  constructor(
    public ThanhVienService: ThanhVienService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.GetByQueryString();
  }

  GetByQueryString() {
    this.isShowLoading = true;
    this.ThanhVienService.GetByIDAsync(3).subscribe(res => {
      this.ThanhVienService.formData = res as ThanhVien;
      this.isShowLoading = false;
    },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSubmit(form: NgForm) {
    this.isShowLoading = true;
    this.ThanhVienService.ThayDoiMatKhauAsync(form.value, this.ThanhVienService.formData.MatKhau01, this.ThanhVienService.formData.MatKhau02, this.ThanhVienService.formData.MatKhau03).subscribe(
      res => {
        this.ThanhVienService.formData.ID = res as number;
        if (this.ThanhVienService.formData.ID == 0) {
          this.NotificationService.warn(environment.SaveNotSuccess);
        }
        else {
          this.NotificationService.warn(environment.SaveSuccess);
        }
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
}