import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationService } from 'src/app/shared/Notification.service';
import { ChuongTrinhQuangBaXucTien } from 'src/app/shared/ChuongTrinhQuangBaXucTien.model';
import { ChuongTrinhQuangBaXucTienService } from 'src/app/shared/ChuongTrinhQuangBaXucTien.service';


@Component({
  selector: 'app-chuong-trinh-quang-ba-xuc-tien-detail',
  templateUrl: './chuong-trinh-quang-ba-xuc-tien-detail.component.html',
  styleUrls: ['./chuong-trinh-quang-ba-xuc-tien-detail.component.css']
})
export class ChuongTrinhQuangBaXucTienDetailComponent implements OnInit {

  ID: number = environment.InitializationNumber;
  isShowLoading: boolean = false;

  
  constructor(
    public ChuongTrinhQuangBaXucTienService: ChuongTrinhQuangBaXucTienService,
    public NotificationService: NotificationService,
    public dialogRef: MatDialogRef<ChuongTrinhQuangBaXucTienDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.ID = data["ID"] as number;
  }
  ngOnInit(): void {    
    
  }
  
  onClose() {
    this.dialogRef.close();
  }

  onSubmit(form: NgForm) {
    this.ChuongTrinhQuangBaXucTienService.SaveAsync(form.value).subscribe(
      res => {
        this.ChuongTrinhQuangBaXucTienService.formData = res as ChuongTrinhQuangBaXucTien;
        this.onClose();
        this.NotificationService.success(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  NgayBatDauChange(value) {
    this.ChuongTrinhQuangBaXucTienService.formData.NgayBatDau = new Date(value);
  }
  NgayKetThucChange(value) {
    this.ChuongTrinhQuangBaXucTienService.formData.NgayKetThuc = new Date(value);
  }
}
