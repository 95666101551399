import { Component, OnInit } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets, Chart, ChartConfiguration, ChartData } from 'chart.js';
import { Color, Label, SingleDataSet, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { DownloadService } from 'src/app/shared/Download.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  isShowLoading: boolean = false;
  year: number = new Date().getFullYear();
  duKhach: number = Math.floor(Math.random() * this.year * 1000);
  donViDuLich: number = Math.floor(Math.random() * this.year * 100);
  tourDuLich: number = Math.floor(Math.random() * this.year * 10);
  hoatDongDuLich: number = Math.floor(Math.random() * this.year * 10);
  constructor(
    public DownloadService: DownloadService,
  ) { }

  ngOnInit(): void {
    this.Report001Async();
  }
  public ChartOptionsReport001: ChartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          return Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
          });
        }
      }
    }
  };
  public ChartColorsReport001: Color[] = [
    { backgroundColor: 'limegreen' },
  ]
  public ChartLabelsReport001: Label[] = [];
  public ChartTypeReport001: ChartType = 'bar';
  public ChartLegendReport001 = true;
  public ChartPluginsReport001 = [];

  public ChartDataReport001: ChartDataSets[] = [
  ];


  public ChartOptionsReport002: ChartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + ': ' + Number(value).toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
          });
        }
      }
    }
  };
  public ChartColorsReport002: Color[] = [
  ]
  public ChartLabelsReport002: Label[] = [];
  public ChartTypeReport002: ChartType = 'doughnut';
  public ChartLegendReport002 = true;
  public ChartPluginsReport002 = [];

  public ChartDataReport002: ChartDataSets[] = [
  ];

  public ChartOptionsReport003: ChartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.labels[tooltipItem.index];
          var value = data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index];
          return label + ': ' + Number(value).toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
          });
        }
      }
    }
  };
  public ChartColorsReport003: Color[] = [
  ]
  public ChartLabelsReport003: Label[] = [];
  public ChartTypeReport003: ChartType = 'polarArea';
  public ChartLegendReport003 = true;
  public ChartPluginsReport003 = [];

  public ChartDataReport003: ChartDataSets[] = [
  ];

  public ChartOptionsReport004: ChartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label;
          return label + ': ' + Number(tooltipItem.yLabel).toFixed(0).replace(/./g, function (c, i, a) {
            return i > 0 && c !== "." && (a.length - i) % 3 === 0 ? "." + c : c;
          });
        }
      }
    }
  };
  public ChartColorsReport004: Color[] = [
  ]
  public ChartLabelsReport004: Label[] = ['Tháng 01', 'Tháng 02', 'Tháng 03', 'Tháng 04', 'Tháng 05', 'Tháng 06', 'Tháng 07', 'Tháng 08', 'Tháng 09', 'Tháng 10', 'Tháng 11', 'Tháng 12'];
  public ChartTypeReport004: ChartType = 'line';
  public ChartLegendReport004 = true;
  public ChartPluginsReport004 = [
  ];

  public ChartDataReport004: ChartDataSets[] = [
  ];

  Report001Async() {
    this.isShowLoading = true;
    let Thang = [];
    let ThongKe001 = [];
    for (let i = 1; i < 13; i++) {
      Thang.push(i);
      ThongKe001.push(Math.floor(Math.random() * i));
    }
    let label: string = 'Lượng truy cập ứng dụng của du khách năm ' + this.year;
    this.ChartLabelsReport001 = Thang;
    this.ChartDataReport001 = [{ data: ThongKe001, label: label, stack: 'a' }];

    let QuocGia = [];
    let ThongKe002 = [];

    QuocGia.push("Việt Nam");
    QuocGia.push("Trung Quốc");
    QuocGia.push("Hoa Kỳ");
    QuocGia.push("Nhật Bản");
    QuocGia.push("Hàn Quốc");

    ThongKe002.push(Math.floor(Math.random() * this.year));
    ThongKe002.push(Math.floor(Math.random() * this.year));
    ThongKe002.push(Math.floor(Math.random() * this.year));
    ThongKe002.push(Math.floor(Math.random() * this.year));
    ThongKe002.push(Math.floor(Math.random() * this.year));

    this.ChartLabelsReport002 = QuocGia;
    this.ChartDataReport002 = [{ data: ThongKe002, label: label }];

    let TourDuLich = [];
    let ThongKe003 = [];

    TourDuLich.push("Địa đạo Củ Chi");
    TourDuLich.push("Rừng Cần Giờ");
    TourDuLich.push("Hóc Môn");
    TourDuLich.push("Trung tâm thành phố");
    TourDuLich.push("Du lịch sông Sài Gòn");

    ThongKe003.push(Math.floor(Math.random() * this.year));
    ThongKe003.push(Math.floor(Math.random() * this.year));
    ThongKe003.push(Math.floor(Math.random() * this.year));
    ThongKe003.push(Math.floor(Math.random() * this.year));
    ThongKe003.push(Math.floor(Math.random() * this.year));

    this.ChartLabelsReport003 = TourDuLich;
    this.ChartDataReport003 = [{ data: ThongKe003, label: label }];
    // let DoanhThu41 = [];
    // for (let i = 1; i < 13; i++) {      
    //   DoanhThu41.push(Math.floor(Math.random() * this.year));     
    // }
    // let ThongKe0041: any = {
    //   type: "line",
    //   fill: false,
    //   data: DoanhThu41,
    //   label: "VIETNAM BOOKING",
    //   borderColor: this.DownloadService.GetRandomColor(Math.floor(Math.random() * this.year))
    // }
    // this.ChartDataReport004.push(ThongKe0041);

    let DoanhThu42 = [];
    for (let i = 1; i < 13; i++) {      
      DoanhThu42.push(Math.floor(Math.random() * this.year));     
    }
    let ThongKe0042: any = {
      type: "line",
      fill: false,
      data: DoanhThu42,
      label: "DU LỊCH VIỆT",
      borderColor: this.DownloadService.GetRandomColor(Math.floor(Math.random() * this.year))
    }
    this.ChartDataReport004.push(ThongKe0042);

    let DoanhThu43 = [];
    for (let i = 1; i < 13; i++) {      
      DoanhThu43.push(Math.floor(Math.random() * this.year)*2);     
    }
    let ThongKe0043: any = {
      type: "line",
      fill: false,
      data: DoanhThu43,
      label: "SAIGONTOURIST",
      borderColor: this.DownloadService.GetRandomColor(Math.floor(Math.random() * this.year))
    }
    this.ChartDataReport004.push(ThongKe0043);

    // let DoanhThu44 = [];
    // for (let i = 1; i < 13; i++) {      
    //   DoanhThu44.push(Math.floor(Math.random() * this.year)*2);     
    // }
    // let ThongKe0044: any = {
    //   type: "line",
    //   fill: false,
    //   data: DoanhThu44,
    //   label: "LỮ HÀNH VIỆT",
    //   borderColor: this.DownloadService.GetRandomColor(Math.floor(Math.random() * this.year))
    // }
    // this.ChartDataReport004.push(ThongKe0044);

    let DoanhThu45 = [];
    for (let i = 1; i < 13; i++) {      
      DoanhThu45.push(Math.floor(Math.random() * this.year)*3);     
    }
    let ThongKe0045: any = {
      type: "line",
      fill: false,
      data: DoanhThu45,
      label: "BENTHANH TOURIST",
      borderColor: this.DownloadService.GetRandomColor(Math.floor(Math.random() * this.year))
    }
    this.ChartDataReport004.push(ThongKe0045);
  }
}
