import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTruFile } from 'src/app/shared/CoSoLuuTruFile.model';
import { CoSoLuuTruFileService } from 'src/app/shared/CoSoLuuTruFile.service';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';

@Component({
  selector: 'app-file',
  templateUrl: './file.component.html',
  styleUrls: ['./file.component.css']
})
export class FileComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;
  FileFileToUpload:any;
  isCoSoLuuTruFile: boolean = false;
  

  constructor(
    public CoSoLuuTruFileService: CoSoLuuTruFileService,
    public CoSoLuuTruService: CoSoLuuTruService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.CoSoLuuTruGetAllToListAsync();
    this.GetAllToListAsync();
  }

  CoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.CoSoLuuTruService.list = res as CoSoLuuTru[];
        this.CoSoLuuTruService.list= this.CoSoLuuTruService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruFileService.GetByParentIDToListAsync(this.parentID).subscribe(
      res => {
        this.CoSoLuuTruFileService.list = res as CoSoLuuTruFile[];
        this.dataSource = new MatTableDataSource(this.CoSoLuuTruFileService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetAllToListAsync();
    }
  }
  onSave(element: CoSoLuuTruFile) {
    element.ParentID=this.parentID;
    this.CoSoLuuTruFileService.SaveAsync(element).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: CoSoLuuTruFile) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruFileService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  FileChangeFiles(files: FileList) {
    if (files) {
      this.FileFileToUpload = files;
      this.isCoSoLuuTruFile=true;
    }
  }
  FileSubmitFiles() {
    if(this.FileFileToUpload != null && this.FileFileToUpload.length>0){
      let fileToUpload: FileList;
      fileToUpload = this.FileFileToUpload;
      this.isShowLoading = true;
      this.CoSoLuuTruFileService.formData.ParentID= this.CoSoLuuTruService.formData.ID;
      this.CoSoLuuTruFileService.SaveAndUploadFilesAsync(this.CoSoLuuTruFileService.formData,fileToUpload).subscribe(
        res => {
          this.onSearch();
          this.isShowLoading = false;        
          this.NotificationService.warn(environment.UploadSuccess);
        },
        err => {
          this.isShowLoading = false;
          this.NotificationService.warn(environment.UploadNotSuccess);
        }
      );
    }
  } 
}

