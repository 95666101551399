import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTruVideo } from 'src/app/shared/CoSoLuuTruVideo.model';
import { CoSoLuuTruVideoService } from 'src/app/shared/CoSoLuuTruVideo.service';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.css']
})
export class VideoComponent implements OnInit {
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;
  VideoFileToUpload:any;
  isCoSoLuuTruVideo: boolean = false;

  constructor(
    public CoSoLuuTruVideoService: CoSoLuuTruVideoService,
    public CoSoLuuTruService: CoSoLuuTruService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.CoSoLuuTruGetAllToListAsync();
    this.GetAllToListAsync();
  }

  CoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.CoSoLuuTruService.list = res as CoSoLuuTru[];
        this.CoSoLuuTruService.list= this.CoSoLuuTruService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruVideoService.GetByParentIDToListAsync(this.parentID).subscribe(
      res => {
        this.CoSoLuuTruVideoService.list = res as CoSoLuuTruVideo[];
        this.dataSource = new MatTableDataSource(this.CoSoLuuTruVideoService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetAllToListAsync();
    }
  }
  onSave(element: CoSoLuuTruVideo) {
    element.ParentID=this.parentID;
    this.CoSoLuuTruVideoService.SaveAsync(element).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: CoSoLuuTruVideo) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruVideoService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }

  VideoChangeFiles(files: FileList) {
    if (files) {
      this.VideoFileToUpload = files;
      this.isCoSoLuuTruVideo=true;
    }
  }
  VideoSubmitFiles() {
    if(this.VideoFileToUpload != null && this.VideoFileToUpload.length>0){
      let fileToUpload: FileList;
      fileToUpload = this.VideoFileToUpload;
      this.isShowLoading = true;
      this.CoSoLuuTruVideoService.formData.ParentID= this.CoSoLuuTruService.formData.ID;
      this.CoSoLuuTruVideoService.SaveAndUploadVideosAsync(this.CoSoLuuTruVideoService.formData,fileToUpload).subscribe(
        res => {
          this.onSearch();
          this.isShowLoading = false;        
          this.NotificationService.warn(environment.UploadSuccess);
        },
        err => {
          this.isShowLoading = false;
          this.NotificationService.warn(environment.UploadNotSuccess);
        }
      );
    }

  } 
}

