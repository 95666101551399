import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoSoLuuTruSuKien } from 'src/app/shared/CoSoLuuTruSuKien.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class CoSoLuuTruSuKienService extends BaseService{
    displayColumns: string[] = ['Name', 'NgayBatDau', 'NgayKetThuc', 'Active', 'Save']; 
    list: CoSoLuuTruSuKien[] | undefined;
    listSearch: CoSoLuuTruSuKien[] | undefined;
    listSearch001: CoSoLuuTruSuKien[] | undefined;
    formData!: CoSoLuuTruSuKien;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "CoSoLuuTruSuKien";
    }
}

