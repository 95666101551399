import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ChuongTrinhQuangBaXucTien } from 'src/app/shared/ChuongTrinhQuangBaXucTien.model';
import { ChuongTrinhQuangBaXucTienService } from 'src/app/shared/ChuongTrinhQuangBaXucTien.service';
import { ChuongTrinhQuangBaXucTienDetailComponent } from 'src/app/chuong-trinh-quang-ba-xuc-tien/chuong-trinh-quang-ba-xuc-tien-detail/chuong-trinh-quang-ba-xuc-tien-detail.component';

@Component({
  selector: 'app-chuong-trinh-quang-ba-xuc-tien',
  templateUrl: './chuong-trinh-quang-ba-xuc-tien.component.html',
  styleUrls: ['./chuong-trinh-quang-ba-xuc-tien.component.css']
})
export class ChuongTrinhQuangBaXucTienComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  constructor(
    public ChuongTrinhQuangBaXucTienService: ChuongTrinhQuangBaXucTienService,
    public NotificationService: NotificationService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.GetAllToListAsync();
  }
  GetAllToListAsync() {
    this.isShowLoading = true;
    this.ChuongTrinhQuangBaXucTienService.GetAllToListAsync().subscribe(
      res => {
        this.ChuongTrinhQuangBaXucTienService.list = res as ChuongTrinhQuangBaXucTien[];
        this.dataSource = new MatTableDataSource(this.ChuongTrinhQuangBaXucTienService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetAllToListAsync();
    }
  }
  onSave(element: ChuongTrinhQuangBaXucTien) {
    this.ChuongTrinhQuangBaXucTienService.SaveAsync(element).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: ChuongTrinhQuangBaXucTien) {
    if (confirm(environment.DeleteConfirm)) {
      this.ChuongTrinhQuangBaXucTienService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  onAdd(ID: any) {
    this.ChuongTrinhQuangBaXucTienService.GetByIDAsync(ID).subscribe(
      res => {
        this.ChuongTrinhQuangBaXucTienService.formData = res as ChuongTrinhQuangBaXucTien;  
        if(ID==0){
          this.ChuongTrinhQuangBaXucTienService.formData.NgayBatDau= new Date();
          this.ChuongTrinhQuangBaXucTienService.formData.NgayKetThuc= new Date();
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(ChuongTrinhQuangBaXucTienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.GetAllToListAsync();
        });
      },
      err => {
      }
    );
  }
}