import { Base } from "./Base.model";

export class CoSoLuuTru extends Base{
    MaSoThue?: string;
    MaSoDangKyKinhDoanh?: string;
    TenDangKyKinhDoanh?: string;
    DiaChi?: string;
    DienThoai?: string;
    Email?: string;
    Website?: string;
    Facebook?: string;
    Tiktok?: string;
    DanhMucTinhThanhID?: number;
    DanhMucQuanHuyenID?: number;
    DanhMucXaPhuongID?: number;
    NguoiDaiDienPhapLuat?: string;
    NguoiDaiDienPhapLuatDienThoai?: string;
    NguoiDaiDienPhapLuatCCCD?: string;
}

