import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTruSuKien } from 'src/app/shared/CoSoLuuTruSuKien.model';
import { CoSoLuuTruSuKienService } from 'src/app/shared/CoSoLuuTruSuKien.service';
import { CoSoLuuTruSuKienDetailComponent } from 'src/app/co-so-luu-tru-su-kien/co-so-luu-tru-su-kien-detail/co-so-luu-tru-su-kien-detail.component';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';

@Component({
  selector: 'app-su-kien',
  templateUrl: './su-kien.component.html',
  styleUrls: ['./su-kien.component.css']
})
export class SuKienComponent implements OnInit {

  dataSource: MatTableDataSource<any>;  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;
  constructor(
    public CoSoLuuTruSuKienService: CoSoLuuTruSuKienService,
    public CoSoLuuTruService: CoSoLuuTruService,
    public NotificationService: NotificationService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.CoSoLuuTruGetAllToListAsync();
    this.GetByParentIDToListAsync();
  }

  CoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.CoSoLuuTruService.list = res as CoSoLuuTru[];
        this.CoSoLuuTruService.list= this.CoSoLuuTruService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetByParentIDToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruSuKienService.GetByParentIDToListAsync(this.parentID).subscribe(
      res => {
        this.CoSoLuuTruSuKienService.list = res as CoSoLuuTruSuKien[];
        this.dataSource = new MatTableDataSource(this.CoSoLuuTruSuKienService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetByParentIDToListAsync();
    }
  }
  onAdd(ID: any) {
    this.CoSoLuuTruSuKienService.GetByIDAsync(ID).subscribe(
      res => {
        this.CoSoLuuTruSuKienService.formData = res as CoSoLuuTruSuKien;  
        this.CoSoLuuTruSuKienService.formData.ParentID= this.parentID;
        if(ID==0){
          this.CoSoLuuTruSuKienService.formData.NgayBatDau= new Date();
          this.CoSoLuuTruSuKienService.formData.NgayKetThuc= new Date();
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CoSoLuuTruSuKienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.GetByParentIDToListAsync();
        });
      },
      err => {
      }
    );
  }
  onDelete(element: CoSoLuuTruSuKien) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruSuKienService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
}
