import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTruHinhAnh } from 'src/app/shared/CoSoLuuTruHinhAnh.model';
import { CoSoLuuTruHinhAnhService } from 'src/app/shared/CoSoLuuTruHinhAnh.service';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';

@Component({
  selector: 'app-hinh-anh',
  templateUrl: './hinh-anh.component.html',
  styleUrls: ['./hinh-anh.component.css']
})
export class HinhAnhComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;

  HinhAnhFileToUpload:any;
  isCoSoLuuTruHinhAnh: boolean = false;

  constructor(
    public CoSoLuuTruHinhAnhService: CoSoLuuTruHinhAnhService,
    public CoSoLuuTruService: CoSoLuuTruService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.CoSoLuuTruGetAllToListAsync();
    this.GetAllToListAsync();
  }

  CoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.CoSoLuuTruService.list = res as CoSoLuuTru[];
        this.CoSoLuuTruService.list= this.CoSoLuuTruService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruHinhAnhService.GetByParentIDToListAsync(this.parentID).subscribe(
      res => {
        this.CoSoLuuTruHinhAnhService.list = res as CoSoLuuTruHinhAnh[];
        this.dataSource = new MatTableDataSource(this.CoSoLuuTruHinhAnhService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetAllToListAsync();
    }
  }
  onSave(element: CoSoLuuTruHinhAnh) {
    element.ParentID=this.parentID;
    this.CoSoLuuTruHinhAnhService.SaveAsync(element).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: CoSoLuuTruHinhAnh) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruHinhAnhService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }

  HinhAnhChangeFiles(files: FileList) {
    if (files) {
      this.HinhAnhFileToUpload = files;
      this.isCoSoLuuTruHinhAnh=true;
    }
  }
  HinhAnhSubmitFiles() {
    if(this.HinhAnhFileToUpload != null && this.HinhAnhFileToUpload.length > 0 ){
      let fileToUpload: FileList;
      fileToUpload = this.HinhAnhFileToUpload;
      this.isShowLoading = true;
      this.CoSoLuuTruHinhAnhService.formData.ParentID= this.CoSoLuuTruService.formData.ID;
      this.CoSoLuuTruHinhAnhService.SaveAndUploadHinhAnhsAsync(this.CoSoLuuTruHinhAnhService.formData,fileToUpload).subscribe(
        res => {
          this.onSearch();
          this.isShowLoading = false;        
          this.NotificationService.warn(environment.UploadSuccess);
        },
        err => {
          this.isShowLoading = false;
          this.NotificationService.warn(environment.UploadNotSuccess);
        }
      );
    }
  } 
}

