import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class DanhMucXaPhuongService extends BaseService {
    displayColumns: string[] = ['Code', 'Name', 'Note', 'SortOrder', 'Active', 'Save'];
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "DanhMucXaPhuong";
    }
    GetByThanhVienIDToListAsync() {
        let thanhVienIDString = localStorage.getItem(environment.ThanhVienID);
        if (thanhVienIDString) {
            let thanhVienID = Number(thanhVienIDString);
            let url = this.aPIURL + this.controller + '/GetByThanhVienIDToListAsync';
            const formUpload: FormData = new FormData();
            formUpload.append('thanhVienID', JSON.stringify(thanhVienID));
            return this.httpClient.post(url, formUpload);
        }
    }
    GetByParentIDAndThanhVienIDToListAsync(parentID: number) {
        let thanhVienIDString = localStorage.getItem(environment.ThanhVienID);
        if (thanhVienIDString) {
            let thanhVienID = Number(thanhVienIDString);
            let url = this.aPIURL + this.controller + '/GetByParentIDAndThanhVienIDToListAsync';
            const formUpload: FormData = new FormData();
            formUpload.append('parentID', JSON.stringify(parentID));
            formUpload.append('thanhVienID', JSON.stringify(thanhVienID));
            return this.httpClient.post(url, formUpload);
        }
    }
    GetByDanhMucXaPhuongByQuanHuyenIDTinhThanhIDToListAsync(tinhThanhID: number,quanHuyenID:number) {
        let url = this.aPIURL + this.controller + '/GetByDanhMucXaPhuongByQuanHuyenIDTinhThanhIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('tinhThanhID', JSON.stringify(tinhThanhID));
        formUpload.append('quanHuyenID', JSON.stringify(quanHuyenID));
        return this.httpClient.post(url, formUpload);
    }
}
    
   
