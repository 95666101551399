<div class="row">
    <div id="breadcrumbs-wrapper" data-image="https://crm.vnptvungtau.vn/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Cơ quan quản lý
                    </h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">CMS</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">Cơ quan quản lý
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <div id="basic-tabs" class="card card card-default scrollspy">
                            <div class="card-content">

                                <div id="1">
                                    <form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
                                        <div class="col s6 m6 l12">
                                            <div class="card-title">
                                                <a title="Danh sách" href="javascript:window.open('','_self').close();"
                                                    style="padding-top: 3px;"
                                                    class="btn-floating waves-effect waves-light green darken-1"><i
                                                        class="material-icons">arrow_back</i></a>&nbsp;&nbsp;&nbsp;

                                                <button [disabled]="!CoQuanQuanLyService.formData.Name"
                                                    title="Lưu thay đổi" type="submit" style="padding-top: 3px;"
                                                    class="btn-floating waves-effect waves-light cyan"><i
                                                        class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
                                            </div>
                                        </div>
                                        <input name="ID" [(ngModel)]="CoQuanQuanLyService.formData.ID" type="hidden">
                                        <input name="DanhMucTinhThanhID"
                                            [(ngModel)]="CoQuanQuanLyService.formData.DanhMucTinhThanhID" type="hidden">
                                        <div class="col s12 m12 l6">
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">Tên</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Tên cơ quan" name="Name"
                                                        [(ngModel)]="CoQuanQuanLyService.formData.Name" type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="MaSoThue">Mã số thuế</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Mã số thuế" name="MaSoThue"
                                                        [(ngModel)]="CoQuanQuanLyService.formData.MaSoThue" type="text">
                                                </div>
                                            </div>


                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="DienThoai">Điện thoại</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Điện thoại" name="DienThoai"
                                                        [(ngModel)]="CoQuanQuanLyService.formData.DienThoai"
                                                        type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Email">Email</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Email" name="Email"
                                                        [(ngModel)]="CoQuanQuanLyService.formData.Email" type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Website">Website</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Website" name="Website"
                                                        [(ngModel)]="CoQuanQuanLyService.formData.Website" type="text">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col s12 m12 l6">


                                            <!-- <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Note">Thành Phố</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <mat-select
                                                        style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="tinhThanhID" class="browser-default" [(ngModel)]="tinhThanhID" (selectionChange)="DanhMucQuanHuyenGetAllToListAsync()">
                                                        <mat-option [value]="0">
                                                            Chọn Quận / Huyện
                                                        </mat-option>
                                                        <mat-option *ngFor="let item of DanhMucTinhThanhService.list" [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                            </div> -->
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Note">Quận Huyện</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <mat-select
                                                        style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucQuanHuyenID" class="browser-default"
                                                        [(ngModel)]="CoQuanQuanLyService.formData.DanhMucQuanHuyenID"
                                                        (selectionChange)="DanhMucXaPhuongGetAllToListAsync()">
                                                        <mat-option [value]="0">
                                                            Chọn Quận / Huyện
                                                        </mat-option>
                                                        <mat-option *ngFor="let item of DanhMucQuanHuyenService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>

                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Note">Xã Phường</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <mat-select
                                                        style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucXaPhuongID" class="browser-default"
                                                        [(ngModel)]="CoQuanQuanLyService.formData.DanhMucXaPhuongID">
                                                        <mat-option [value]="0">
                                                            Chọn Xã / Phường
                                                        </mat-option>
                                                        <mat-option *ngFor="let item of DanhMucXaPhuongService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Note">Địa chỉ</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <div class="input-field">
                                                        <input placeholder="Số nhà/ tên đường" name="DiaChi"
                                                            [(ngModel)]="CoQuanQuanLyService.formData.DiaChi"
                                                            type="text">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Note">Ghi chú</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Ghi chú" name="Note"
                                                        [(ngModel)]="CoQuanQuanLyService.formData.Note" type="text">
                                                </div>
                                            </div>

                                            <!-- <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">Facebook</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Facebook" name="Facebook"
                                                        [(ngModel)]="CoQuanQuanLyService.formData.Facebook" type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">Tiktok</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Tiktok" name="Tiktok"
                                                        [(ngModel)]="CoQuanQuanLyService.formData.Tiktok" type="text">
                                                </div>
                                            </div> -->



                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<br />
<app-loading *ngIf="isShowLoading"></app-loading>