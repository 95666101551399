import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoSoLuuTruHoatDong } from 'src/app/shared/CoSoLuuTruHoatDong.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class CoSoLuuTruHoatDongService extends BaseService{
    displayColumns: string[] = ['Name', 'NgayBatDau', 'NgayKetThuc', 'Active', 'Save']; 
    list: CoSoLuuTruHoatDong[] | undefined;
    listSearch: CoSoLuuTruHoatDong[] | undefined;
    listSearch001: CoSoLuuTruHoatDong[] | undefined;
    formData!: CoSoLuuTruHoatDong;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "CoSoLuuTruHoatDong";
    }
}

