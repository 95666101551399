import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class CoSoLuuTruService extends BaseService{
    displayColumns: string[] = ['TenDangKyKinhDoanh','MaSoDangKyKinhDoanh','MaSoThue','Active', 'Save']; 
    list: CoSoLuuTru[] | undefined;
    formData!: CoSoLuuTru;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "CoSoLuuTru";
    }    

    GetByIDStringAsync(ID: string) {
        let url = this.aPIURL + this.controller + '/GetByIDStringAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('ID', JSON.stringify(ID));        
        return this.httpClient.post(url, formUpload);
    }
    GetByParentID_QuanHuyenID_XaPhuongIDAsync(parentID: number,quanHuyenID: number,xaPhuongID: number){
        let url = this.aPIURL + this.controller + '/GetByParentID_QuanHuyenID_XaPhuongIDAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('parentID', JSON.stringify(parentID));     
        formUpload.append('quanHuyenID', JSON.stringify(quanHuyenID));  
        formUpload.append('xaPhuongID', JSON.stringify(xaPhuongID));     
        return this.httpClient.post(url, formUpload);
    }
    GetByThanhVienIDAsync() {
        let thanhVienIDString = localStorage.getItem(environment.ThanhVienID);
        if (thanhVienIDString) {
            let thanhVienID = Number(thanhVienIDString);
            let url = this.aPIURL + this.controller + '/GetByThanhVienIDAsync';
            const formUpload: FormData = new FormData();
            formUpload.append('thanhVienID', JSON.stringify(thanhVienID));
            return this.httpClient.post(url, formUpload);
        }
    }
}
