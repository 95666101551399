import { Component } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { DownloadService } from 'src/app/shared/Download.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienLichSuTruyCap } from 'src/app/shared/ThanhVienLichSuTruyCap.model';
import { ThanhVienLichSuTruyCapService } from 'src/app/shared/ThanhVienLichSuTruyCap.service';
import { DanhMucChucNang } from 'src/app/shared/DanhMucChucNang.model';
import { DanhMucChucNangService } from 'src/app/shared/DanhMucChucNang.service';
import { ThanhVienToken } from 'src/app/shared/ThanhVienToken.model';
import { ThanhVienTokenService } from 'src/app/shared/ThanhVienToken.service';
import { ThongBao } from 'src/app/shared/ThongBao.model';
import { ThongBaoService } from 'src/app/shared/ThongBao.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CRM';
  domainName = environment.DomainDestination;
  queryString: string = environment.InitializationString;
  queryStringSub: string = environment.InitializationString;
  constructor(
    public router: Router,
    public DownloadService: DownloadService,
    public ThanhVienService: ThanhVienService,
    public ThanhVienLichSuTruyCapService: ThanhVienLichSuTruyCapService,
    public DanhMucChucNangService: DanhMucChucNangService,
    public ThanhVienTokenService: ThanhVienTokenService,
    public ThongBaoService: ThongBaoService,
  ) {
    this.getByQueryString();
  }
  getByQueryString() {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        if (this.queryString.indexOf(environment.AuthenticationToken) > -1) {
          localStorage.setItem(environment.AuthenticationToken, this.queryString.split('=')[this.queryString.split('=').length - 1]);
        }
        this.AuthenticationToken();
      }
    });
  }
  AuthenticationToken() {
    let token = localStorage.getItem(environment.AuthenticationToken);    
    let isLogin = true;
    if (token == null) {
      isLogin = false;
    }
    else {
      this.ThanhVienTokenService.AuthenticationByTokenAsync(token).subscribe(
        res => {
          this.ThanhVienTokenService.formData = res as ThanhVienToken;
          if (this.ThanhVienTokenService.formData != null) {
            if (this.ThanhVienTokenService.formData.ParentID > 0) {
              this.ThanhVienService.formDataLogin.ID = this.ThanhVienTokenService.formData.ParentID;
              this.ThanhVienService.GetByIDAsync(this.ThanhVienTokenService.formData.ParentID).subscribe(
                res => {
                  this.ThanhVienService.formDataLogin = res as ThanhVien;
                  if (this.ThanhVienService.formDataLogin) {
                    localStorage.setItem(environment.ThanhVienID, this.ThanhVienService.formDataLogin.ID.toString());
                    localStorage.setItem(environment.ThanhVienTaiKhoan, this.ThanhVienService.formDataLogin.TaiKhoan);
                    localStorage.setItem(environment.ThanhVienHoTen, this.ThanhVienService.formDataLogin.HoTen);
                    localStorage.setItem(environment.ThanhVienAvatarURL, this.ThanhVienService.formDataLogin.AvatarURL);                    
                    this.ThongBaoGetByThanhVienIDToListAsync();
                    this.ThanhVienLichSuTruyCapSaveAsync(this.queryString);
                  }
                  else {
                    isLogin = false;
                  }
                },
                err => {
                  isLogin = false;
                }
              );
            }
            else {
              isLogin = false;
            }
          }
          else {
            isLogin = false;
          }
        },
        err => {
          isLogin = false;
        }
      );
    }
    if (isLogin == false) {
      let destinationURL = environment.LoginURL + "?url=" + environment.DomainURL;
      window.location.href = destinationURL;
    }
  } 
  ThongBaoGetByThanhVienIDToListAsync() {
    this.ThongBaoService.GetByThanhVienIDToListAsync().subscribe(
      res => {
        this.ThongBaoService.list = (res as ThongBao[]).sort((a, b) => (a.CreatedDate < b.CreatedDate ? 1 : -1));
      },
      err => {
      }
    );
  }
  ThanhVienLichSuTruyCapSaveAsync(url: string) {
    url = environment.DomainURL + "#" + url;
    this.ThanhVienLichSuTruyCapService.formData.Code = url;
    this.ThanhVienLichSuTruyCapService.formData.Name = this.ThanhVienService.formDataLogin.TaiKhoan;
    this.ThanhVienLichSuTruyCapService.formData.Note = localStorage.getItem(environment.AuthenticationToken);
    this.ThanhVienLichSuTruyCapService.formData.ParentID = this.ThanhVienService.formDataLogin.ID;
    this.ThanhVienLichSuTruyCapService.SaveAsync(this.ThanhVienLichSuTruyCapService.formData).subscribe(
      res => {
      },
      err => {
      }
    );
  }
  onLogout() {
    localStorage.setItem(environment.AuthenticationToken, environment.InitializationString);
    localStorage.setItem(environment.ThanhVienID, environment.InitializationString);
    localStorage.setItem(environment.ThanhVienHoTen, environment.InitializationString);
    localStorage.setItem(environment.ThanhVienTaiKhoan, environment.InitializationString);
    let destinationURL = environment.LoginURL + "?url=" + environment.DomainURL;
    window.location.href = destinationURL;
  }
}
