import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-co-so-luu-tru-tour',
  templateUrl: './co-so-luu-tru-tour.component.html',
  styleUrls: ['./co-so-luu-tru-tour.component.css']
})
export class CoSoLuuTruTourComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
