import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NotificationService } from 'src/app/shared/Notification.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucQuocGia } from 'src/app/shared/DanhMucQuocGia.model';
import { DanhMucQuocGiaService } from 'src/app/shared/DanhMucQuocGia.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';
import { ThanhVienPhanQuyenUngDung } from 'src/app/shared/ThanhVienPhanQuyenUngDung.model';
import { ThanhVienPhanQuyenUngDungService } from 'src/app/shared/ThanhVienPhanQuyenUngDung.service';
import { ThanhVienPhanQuyenChucNang } from 'src/app/shared/ThanhVienPhanQuyenChucNang.model';
import { ThanhVienPhanQuyenChucNangService } from 'src/app/shared/ThanhVienPhanQuyenChucNang.service';
import { ThanhVienPhanQuyenKhuVuc } from 'src/app/shared/ThanhVienPhanQuyenKhuVuc.model';
import { ThanhVienPhanQuyenKhuVucService } from 'src/app/shared/ThanhVienPhanQuyenKhuVuc.service';
import { ThanhVienToken } from 'src/app/shared/ThanhVienToken.model';
import { ThanhVienTokenService } from 'src/app/shared/ThanhVienToken.service';
import { ThanhVienLichSuTruyCap } from 'src/app/shared/ThanhVienLichSuTruyCap.model';
import { ThanhVienLichSuTruyCapService } from 'src/app/shared/ThanhVienLichSuTruyCap.service';

@Component({
  selector: 'app-thanh-vien-info',
  templateUrl: './thanh-vien-info.component.html',
  styleUrls: ['./thanh-vien-info.component.css']
})
export class ThanhVienInfoComponent implements OnInit {

  isShowLoading: boolean = false;
  queryString: string = environment.InitializationString;
  URLSub: string = environment.DomainDestination + "ThanhVienInfo";
  danhMucQuanHuyenID: number = environment.InitializationNumber;

  searchStringThanhVienToken: string = environment.InitializationString;
  searchStringThanhVienLichSuTruyCap: string = environment.InitializationString;

  ThanhVienPhanQuyenUngDungIsSelectAll: boolean = false;
  ThanhVienPhanQuyenChucNangIsSelectAll: boolean = false;
  ThanhVienPhanQuyenKhuVucIsSelectAll: boolean = false;

  dataSourceThanhVienPhanQuyenUngDung: MatTableDataSource<any>;
  @ViewChild('sortThanhVienPhanQuyenUngDung') sortThanhVienPhanQuyenUngDung: MatSort;
  @ViewChild('paginatorThanhVienPhanQuyenUngDung') paginatorThanhVienPhanQuyenUngDung: MatPaginator;

  dataSourceThanhVienPhanQuyenChucNang: MatTableDataSource<any>;
  @ViewChild('sortThanhVienPhanQuyenChucNang') sortThanhVienPhanQuyenChucNang: MatSort;
  @ViewChild('paginatorThanhVienPhanQuyenChucNang') paginatorThanhVienPhanQuyenChucNang: MatPaginator;

  dataSourceThanhVienPhanQuyenKhuVuc: MatTableDataSource<any>;
  @ViewChild('sortThanhVienPhanQuyenKhuVuc') sortThanhVienPhanQuyenKhuVuc: MatSort;
  @ViewChild('paginatorThanhVienPhanQuyenKhuVuc') paginatorThanhVienPhanQuyenKhuVuc: MatPaginator;

  dataSourceThanhVienToken: MatTableDataSource<any>;
  @ViewChild('sortThanhVienToken') sortThanhVienToken: MatSort;
  @ViewChild('paginatorThanhVienToken') paginatorThanhVienToken: MatPaginator;

  dataSourceThanhVienLichSuTruyCap: MatTableDataSource<any>;
  @ViewChild('sortThanhVienLichSuTruyCap') sortThanhVienLichSuTruyCap: MatSort;
  @ViewChild('paginatorThanhVienLichSuTruyCap') paginatorThanhVienLichSuTruyCap: MatPaginator;

  constructor(
    public router: Router,
    public ThanhVienService: ThanhVienService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucQuocGiaService: DanhMucQuocGiaService,
    public DanhMucThanhVienService: DanhMucThanhVienService,
    public ThanhVienPhanQuyenUngDungService: ThanhVienPhanQuyenUngDungService,
    public ThanhVienPhanQuyenChucNangService: ThanhVienPhanQuyenChucNangService,
    public ThanhVienPhanQuyenKhuVucService: ThanhVienPhanQuyenKhuVucService,
    public ThanhVienTokenService: ThanhVienTokenService,
    public ThanhVienLichSuTruyCapService: ThanhVienLichSuTruyCapService,
    public NotificationService: NotificationService,
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        this.GetByQueryString();
      }
    });
  }

  ngOnInit(): void {
  }
  DanhMucQuocGiaGetToListAsync() {
    this.DanhMucQuocGiaService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucQuocGiaService.list = (res as DanhMucQuocGia[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
      },
      err => {
      }
    );
  }
  DanhMucThanhVienGetToListAsync() {
    this.DanhMucThanhVienService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucThanhVienService.list = (res as DanhMucThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
      },
      err => {
      }
    );
  }  
  DanhMucTinhThanhGetToListAsync() {
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenGetToListAsync();
      },
      err => {
      }
    );
  }
  DanhMucQuanHuyenGetToListAsync() {    
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync(this.ThanhVienService.formData.DanhMucTinhThanhID).subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongGetToListAsync();
      },
      err => {
      }
    );
  }
  DanhMucXaPhuongGetToListAsync() {
    this.DanhMucXaPhuongService.GetByParentIDToListAsync(this.ThanhVienService.formData.DanhMucQuanHuyenID).subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
      },
      err => {
      }
    );
  }
  ThanhVienPhanQuyenUngDungGetToListAsync() {
    this.ThanhVienPhanQuyenUngDungService.GetByParentIDToListAsync(this.ThanhVienService.formData.ID).subscribe(
      res => {
        this.ThanhVienPhanQuyenUngDungService.list = (res as ThanhVienPhanQuyenUngDung[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.dataSourceThanhVienPhanQuyenUngDung = new MatTableDataSource(this.ThanhVienPhanQuyenUngDungService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSourceThanhVienPhanQuyenUngDung.sort = this.sortThanhVienPhanQuyenUngDung;
        this.dataSourceThanhVienPhanQuyenUngDung.paginator = this.paginatorThanhVienPhanQuyenUngDung;
      },
      err => {
      }
    );
  }
  ThanhVienPhanQuyenChucNangGetToListAsync() {
    this.ThanhVienPhanQuyenChucNangService.GetByParentIDToListAsync(this.ThanhVienService.formData.ID).subscribe(
      res => {
        this.ThanhVienPhanQuyenChucNangService.list = (res as ThanhVienPhanQuyenChucNang[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.dataSourceThanhVienPhanQuyenChucNang = new MatTableDataSource(this.ThanhVienPhanQuyenChucNangService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSourceThanhVienPhanQuyenChucNang.sort = this.sortThanhVienPhanQuyenChucNang;
        this.dataSourceThanhVienPhanQuyenChucNang.paginator = this.paginatorThanhVienPhanQuyenChucNang;
      },
      err => {
      }
    );
  }
  ThanhVienPhanQuyenKhuVucGetToListAsync() {
    this.ThanhVienPhanQuyenKhuVucService.GetByParentIDAndDanhMucQuanHuyenIDToListAsync(this.ThanhVienService.formData.ID, this.danhMucQuanHuyenID).subscribe(
      res => {
        this.ThanhVienPhanQuyenKhuVucService.list = (res as ThanhVienPhanQuyenKhuVuc[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.dataSourceThanhVienPhanQuyenKhuVuc = new MatTableDataSource(this.ThanhVienPhanQuyenKhuVucService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSourceThanhVienPhanQuyenKhuVuc.sort = this.sortThanhVienPhanQuyenKhuVuc;
        this.dataSourceThanhVienPhanQuyenKhuVuc.paginator = this.paginatorThanhVienPhanQuyenKhuVuc;
      },
      err => {
      }
    );
  }
  ThanhVienTokenGetToListAsync() {
    this.ThanhVienTokenService.GetByParentIDToListAsync(this.ThanhVienService.formData.ID).subscribe(
      res => {
        this.ThanhVienTokenService.list = (res as ThanhVienToken[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));          
        this.dataSourceThanhVienToken = new MatTableDataSource(this.ThanhVienTokenService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSourceThanhVienToken.sort = this.sortThanhVienToken;
        this.dataSourceThanhVienToken.paginator = this.paginatorThanhVienToken;        
      },
      err => {
      }
    );
  }
  ThanhVienLichSuTruyCapGetToListAsync() {
    this.ThanhVienLichSuTruyCapService.GetByParentIDToListAsync(this.ThanhVienService.formData.ID).subscribe(
      res => {
        this.ThanhVienLichSuTruyCapService.list = (res as ThanhVienLichSuTruyCap[]).sort((a, b) => (a.CreatedDate < b.CreatedDate ? 1 : -1));
        this.dataSourceThanhVienLichSuTruyCap = new MatTableDataSource(this.ThanhVienLichSuTruyCapService.list);
        this.dataSourceThanhVienLichSuTruyCap.sort = this.sortThanhVienLichSuTruyCap;
        this.dataSourceThanhVienLichSuTruyCap.paginator = this.paginatorThanhVienLichSuTruyCap;
      },
      err => {
      }
    );
  }
  GetByQueryString() {
    this.isShowLoading = true;
    this.ThanhVienService.GetByIDStringAsync(this.queryString).subscribe(res => {
      this.ThanhVienService.formData = res as ThanhVien;
      if (this.ThanhVienService.formData) {
        if (this.ThanhVienService.formData.ID == 0) {
          this.ThanhVienService.formData.DanhMucTinhThanhID = environment.HoChiMinhCityID;          
        }
        this.DanhMucQuocGiaGetToListAsync();
        this.DanhMucTinhThanhGetToListAsync();
        this.DanhMucThanhVienGetToListAsync();             
        if (this.ThanhVienService.formData.ID > 0) {
          this.ThanhVienPhanQuyenUngDungGetToListAsync();
          this.ThanhVienPhanQuyenChucNangGetToListAsync();
          this.ThanhVienPhanQuyenKhuVucGetToListAsync();
          this.ThanhVienTokenGetToListAsync();
          this.ThanhVienLichSuTruyCapGetToListAsync();
        }
      }
      this.isShowLoading = false;
    });
  }
  onSubmit(form: NgForm) {
    this.isShowLoading = true;
    this.ThanhVienService.SaveAndUploadFileAsync(form.value, this.fileToUpload).subscribe(
      res => {
        if (this.ThanhVienService.formData.ID == 0) {
          this.ThanhVienService.formData = res as ThanhVien;
          if (this.ThanhVienService.formData.ID > 0) {
            let url = this.URLSub + "/" + this.ThanhVienService.formData.ID;
            window.location.href = this.URLSub + "/" + this.ThanhVienService.formData.ID;
          }
          else {
            this.NotificationService.warn(environment.UserNameExists);
          }
        }
        else {
          this.NotificationService.warn(environment.SaveSuccess);
        }
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  fileToUpload: any;
  fileToUpload0: File = null;
  changeImage(files: FileList) {
    if (files) {
      this.fileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.ThanhVienService.formData.AvatarURL = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
  onThanhVienPhanQuyenUngDungActiveChange(element: ThanhVienPhanQuyenUngDung, listCase: number) {
    this.ThanhVienPhanQuyenUngDungService.SaveAsync(element).subscribe(
      res => {
        if (listCase == 0) {
          this.ThanhVienPhanQuyenUngDungGetToListAsync();
        }
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onThanhVienPhanQuyenChucNangActiveChange(element: ThanhVienPhanQuyenChucNang, listCase: number) {
    this.ThanhVienPhanQuyenChucNangService.SaveAsync(element).subscribe(
      res => {
        if (listCase == 0) {
          this.ThanhVienPhanQuyenChucNangGetToListAsync();
        }
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onThanhVienPhanQuyenKhuVucActiveChange(element: ThanhVienPhanQuyenKhuVuc, listCase: number) {
    this.ThanhVienPhanQuyenKhuVucService.SaveAsync(element).subscribe(
      res => {
        if (listCase == 0) {
          this.ThanhVienPhanQuyenKhuVucGetToListAsync();
        }
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onThanhVienPhanQuyenUngDungIsSelectAllChange() {
    for (let i = 0; i < this.ThanhVienPhanQuyenUngDungService.list.length; i++) {
      this.ThanhVienPhanQuyenUngDungService.list[i].Active = this.ThanhVienPhanQuyenUngDungIsSelectAll;
      this.onThanhVienPhanQuyenUngDungActiveChange(this.ThanhVienPhanQuyenUngDungService.list[i], 1);
    }
    this.ThanhVienPhanQuyenUngDungGetToListAsync();
  }
  onThanhVienPhanQuyenChucNangIsSelectAllChange() {
    for (let i = 0; i < this.ThanhVienPhanQuyenChucNangService.list.length; i++) {
      this.ThanhVienPhanQuyenChucNangService.list[i].Active = this.ThanhVienPhanQuyenChucNangIsSelectAll;
      this.onThanhVienPhanQuyenChucNangActiveChange(this.ThanhVienPhanQuyenChucNangService.list[i], 1);
    }
    this.ThanhVienPhanQuyenChucNangGetToListAsync();
  }
  onThanhVienPhanQuyenKhuVucIsSelectAllChange() {
    for (let i = 0; i < this.ThanhVienPhanQuyenKhuVucService.list.length; i++) {
      this.ThanhVienPhanQuyenKhuVucService.list[i].Active = this.ThanhVienPhanQuyenKhuVucIsSelectAll;
      this.onThanhVienPhanQuyenKhuVucActiveChange(this.ThanhVienPhanQuyenKhuVucService.list[i], 1);
    }
    this.ThanhVienPhanQuyenKhuVucGetToListAsync();
  }
  ThanhVienTokenSearch() {
    if (this.searchStringThanhVienToken.length > 0) {
      this.dataSourceThanhVienToken.filter = this.searchStringThanhVienToken.toLowerCase();
    }
    else {
      this.ThanhVienTokenGetToListAsync();
    }
  }
  ThanhVienLichSuTruyCapSearch() {
    if (this.searchStringThanhVienLichSuTruyCap.length > 0) {
      this.dataSourceThanhVienLichSuTruyCap.filter = this.searchStringThanhVienLichSuTruyCap.toLowerCase();
    }
    else {
      this.ThanhVienLichSuTruyCapGetToListAsync();
    }
  }
}
