<div class="row">
    <div class="col s12">
        <div class="container">
            <div id="card-stats">
                <div class="row">
                    <div class="col s12 m6 l3">
                        <div class="card animate fadeLeft">
                            <div class="card-content cyan white-text">
                                <p class="card-stats-title"><i class="material-icons">person_outline</i> Du khách</p>
                                <h4 class="card-stats-number white-text"> {{duKhach | number:
                                    '1.0-0'}}</h4>
                                <p class="card-stats-compare">
                                    <i class="material-icons">keyboard_arrow_up</i>Đăng ký và kích hoạt
                                </p>
                            </div>
                            <div class="card-action cyan darken-1">
                                <div id="clients-bar" class="center-align"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col s12 m6 l3">
                        <div class="card animate fadeLeft">
                            <div class="card-content red accent-2 white-text">
                                <p class="card-stats-title"><i class="material-icons">account_balance</i>Đơn vị du lịch
                                </p>
                                <h4 class="card-stats-number white-text">{{donViDuLich | number:
                                    '1.0-0'}}</h4>
                                <p class="card-stats-compare">
                                    <i class="material-icons">keyboard_arrow_up</i>Khách sạn, Homestay, đơn vị lữ hành,
                                    điểm du lịch
                                </p>
                            </div>
                            <div class="card-action red">
                                <div id="sales-compositebar" class="center-align"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col s12 m6 l3">
                        <div class="card animate fadeRight">
                            <div class="card-content orange lighten-1 white-text">
                                <p class="card-stats-title"><i class="material-icons">local_hotel</i> Tour du lịch</p>
                                <h4 class="card-stats-number white-text">{{tourDuLich | number:
                                    '1.0-0'}}</h4>
                                <p class="card-stats-compare">
                                    <i class="material-icons">keyboard_arrow_up</i>Đăng ký và xác nhận
                                </p>
                            </div>
                            <div class="card-action orange">
                                <div id="profit-tristate" class="center-align"></div>
                            </div>
                        </div>
                    </div>
                    <div class="col s12 m6 l3">
                        <div class="card animate fadeRight">
                            <div class="card-content green lighten-1 white-text">
                                <p class="card-stats-title"><i class="material-icons">airport_shuttle</i> Hoạt động du
                                    lịch</p>
                                <h4 class="card-stats-number white-text">{{hoatDongDuLich | number:
                                    '1.0-0'}}</h4>
                                <p class="card-stats-compare">
                                    <i class="material-icons">keyboard_arrow_down</i>Đăng ký, xác nhận và triển khai
                                </p>
                            </div>
                            <div class="card-action green">
                                <div id="invoice-line" class="center-align"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12 m12 l6">     
        <h5 style="text-align: center;"><b>Thống kê du khách theo quốc gia</b></h5>   
        <canvas baseChart [datasets]="ChartDataReport002"
            [labels]="ChartLabelsReport002"
            [chartType]="ChartTypeReport002"
            [colors]="ChartColorsReport002"
            [options]="ChartOptionsReport002">
        </canvas>      
        <h5 style="text-align: center;"><b>Lượng truy cập ứng dụng của du khách năm {{year}}</b></h5>   
        <canvas baseChart [datasets]="ChartDataReport001"
            [labels]="ChartLabelsReport001"
            [chartType]="ChartTypeReport001"
            [colors]="ChartColorsReport001"
            [options]="ChartOptionsReport001">
        </canvas>               
    </div>
    <div class="col s12 m12 l6">        
        <h5 style="text-align: center;"><b>Thống kê du khách theo tour du lịch</b></h5>   
        <canvas baseChart [datasets]="ChartDataReport003"
            [labels]="ChartLabelsReport003"
            [chartType]="ChartTypeReport003"
            [colors]="ChartColorsReport003"
            [options]="ChartOptionsReport003">
        </canvas>      
        <h5 style="text-align: center;"><b>Thống kê du khách theo đơn vị lữ hành</b></h5>   
        <canvas baseChart [datasets]="ChartDataReport004"
            [labels]="ChartLabelsReport004"
            [chartType]="ChartTypeReport004"
            [colors]="ChartColorsReport004"
            [options]="ChartOptionsReport004">
        </canvas>      
    </div>
</div>
<br />
<br />
<br />