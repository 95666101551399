import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienService extends BaseService {
    displayColumns: string[] = ['DanhMucThanhVienID', 'TaiKhoan', 'HoTen', 'DienThoai', 'Active', 'Save'];
    list: ThanhVien[] | undefined;
    formData!: ThanhVien;
    formDataLogin!: ThanhVien;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThanhVien";
        this.formDataLogin = {
            ID: Number(localStorage.getItem(environment.ThanhVienID)),
            TaiKhoan: localStorage.getItem(environment.ThanhVienTaiKhoan),
            HoTen: localStorage.getItem(environment.ThanhVienHoTen),
            AvatarURL: localStorage.getItem(environment.ThanhVienAvatarURL),
        }
    }
    ThayDoiMatKhauAsync(formData: ThanhVien, matKhau01: string, matKhau02: string, matKhau03: string) {
        let url = this.aPIURL + this.controller + '/ThayDoiMatKhauAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(formData));
        formUpload.append('matKhau01', JSON.stringify(matKhau01));
        formUpload.append('matKhau02', JSON.stringify(matKhau02));
        formUpload.append('matKhau03', JSON.stringify(matKhau03));
        return this.httpClient.post(url, formUpload);
    }
    SaveAndUploadFileAsync(formData: ThanhVien, fileToUpload: FileList) {
        var thanhVienID = localStorage.getItem(environment.ThanhVienID);
        if (thanhVienID) {
            formData.LastUpdatedMembershipID = Number(thanhVienID);
        }
        let url = this.aPIURL + this.controller + '/SaveAndUploadFileAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(formData));
        if (fileToUpload) {
            if (fileToUpload.length > 0) {
                for (var i = 0; i < fileToUpload.length; i++) {
                    formUpload.append('file[]', fileToUpload[i]);
                }
            }
        }
        return this.httpClient.post(url, formUpload);
    }
    GetByIDStringAsync(ID: string) {
        let url = this.aPIURL + this.controller + '/GetByIDStringAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('ID', JSON.stringify(ID));
        return this.httpClient.post(url, formUpload);
    }
    GetBySearchStringOrDanhMucThanhVienIDToListAsync(searchString: string, danhMucThanhVienID: number) {
        let url = this.aPIURL + this.controller + '/GetBySearchStringOrDanhMucThanhVienIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('searchString', JSON.stringify(searchString));
        formUpload.append('danhMucThanhVienID', JSON.stringify(danhMucThanhVienID));
        return this.httpClient.post(url, formUpload);
    }
    GetByAllOrSearchStringToListAsync(searchString: string) {
        let url = this.aPIURL + this.controller + '/GetByAllOrSearchStringToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('searchString', JSON.stringify(searchString));
        return this.httpClient.post(url, formUpload);
    }
}

