import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThanhVienThongBao } from 'src/app/shared/ThanhVienThongBao.model';
import { ThanhVienThongBaoService } from 'src/app/shared/ThanhVienThongBao.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';

@Component({
  selector: 'app-thanh-vien-thong-bao',
  templateUrl: './thanh-vien-thong-bao.component.html',
  styleUrls: ['./thanh-vien-thong-bao.component.css']
})
export class ThanhVienThongBaoComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  quanHuyenID: number = environment.InitializationNumber;

  constructor(
    public ThanhVienThongBaoService: ThanhVienThongBaoService,
    public ThanhVienService: ThanhVienService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienGetAllToListAsync();
    this.GetToListAsync();
  }

  ThanhVienGetAllToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienService.GetAllToListAsync().subscribe(
      res => {
        this.ThanhVienService.list = (res as ThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienThongBaoService.GetAllAndEmptyToListAsync().subscribe(
      res => {
        this.ThanhVienThongBaoService.list = (res as ThanhVienThongBao[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.dataSource = new MatTableDataSource(this.ThanhVienThongBaoService.list);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetToListAsync();
    }
  }
  onSave(element: ThanhVienThongBao) {
    this.ThanhVienThongBaoService.SaveAsync(element).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: ThanhVienThongBao) {
    if (confirm(environment.DeleteConfirm)) {
      this.ThanhVienThongBaoService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
}