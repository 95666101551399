import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';
import { NotificationService } from 'src/app/shared/Notification.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { CoSoLuuTruPhong } from 'src/app/shared/CoSoLuuTruPhong.model';
import { CoSoLuuTruPhongService } from 'src/app/shared/CoSoLuuTruPhong.service';
import { PheDuyet } from 'src/app/shared/PheDuyet.model';
import { PheDuyetService } from 'src/app/shared/PheDuyet.service';
import { CoSoLuuTruDiaDiem } from 'src/app/shared/CoSoLuuTruDiaDiem.model';
import { CoSoLuuTruDiaDiemService } from 'src/app/shared/CoSoLuuTruDiaDiem.service';
import { CoSoLuuTruNhaHang } from 'src/app/shared/CoSoLuuTruNhaHang.model';
import { CoSoLuuTruNhaHangService } from 'src/app/shared/CoSoLuuTruNhaHang.service';
import { CoSoLuuTruMonAn } from 'src/app/shared/CoSoLuuTruMonAn.model';
import { CoSoLuuTruMonAnService } from 'src/app/shared/CoSoLuuTruMonAn.service';
import { CoSoLuuTruDichVu } from 'src/app/shared/CoSoLuuTruDichVu.model';
import { CoSoLuuTruDichVuService } from 'src/app/shared/CoSoLuuTruDichVu.service';
import { CoSoLuuTruHoTro } from 'src/app/shared/CoSoLuuTruHoTro.model';
import { CoSoLuuTruHoTroService } from 'src/app/shared/CoSoLuuTruHoTro.service';
import { CoSoLuuTruSocial } from 'src/app/shared/CoSoLuuTruSocial.model';
import { CoSoLuuTruSocialService } from 'src/app/shared/CoSoLuuTruSocial.service';
import { CoSoLuuTruHoatDong } from 'src/app/shared/CoSoLuuTruHoatDong.model';
import { CoSoLuuTruHoatDongService } from 'src/app/shared/CoSoLuuTruHoatDong.service';
import { CoSoLuuTruSuKien } from 'src/app/shared/CoSoLuuTruSuKien.model';
import { CoSoLuuTruSuKienService } from 'src/app/shared/CoSoLuuTruSuKien.service';
import { CoSoLuuTruTour } from 'src/app/shared/CoSoLuuTruTour.model';
import { CoSoLuuTruTourService } from 'src/app/shared/CoSoLuuTruTour.service';
import { CoSoLuuTruHinhAnh } from 'src/app/shared/CoSoLuuTruHinhAnh.model';
import { CoSoLuuTruHinhAnhService } from 'src/app/shared/CoSoLuuTruHinhAnh.service';
import { CoSoLuuTruVideo } from 'src/app/shared/CoSoLuuTruVideo.model';
import { CoSoLuuTruVideoService } from 'src/app/shared/CoSoLuuTruVideo.service';
import { CoSoLuuTruFile } from 'src/app/shared/CoSoLuuTruFile.model';
import { CoSoLuuTruFileService } from 'src/app/shared/CoSoLuuTruFile.service';
import { DanhMucCoSoLuuTru } from 'src/app/shared/DanhMucCoSoLuuTru.model';
import { DanhMucCoSoLuuTruService } from 'src/app/shared/DanhMucCoSoLuuTru.service';
import { CoSoLuuTruHoatDongDetailComponent } from 'src/app/co-so-luu-tru-hoat-dong/co-so-luu-tru-hoat-dong-detail/co-so-luu-tru-hoat-dong-detail.component';
import { CoSoLuuTruSuKienDetailComponent } from 'src/app/co-so-luu-tru-su-kien/co-so-luu-tru-su-kien-detail/co-so-luu-tru-su-kien-detail.component';
import { CoSoLuuTruTourDetailComponent } from 'src/app/co-so-luu-tru-tour/co-so-luu-tru-tour-detail/co-so-luu-tru-tour-detail.component';
import { CoQuanQuanLy } from 'src/app/shared/CoQuanQuanLy.model';
import { CoQuanQuanLyService } from 'src/app/shared/CoQuanQuanLy.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { DanhMucPheDuyet } from 'src/app/shared/DanhMucPheDuyet.model';
import { DanhMucPheDuyetService } from 'src/app/shared/DanhMucPheDuyet.service';


@Component({
  selector: 'app-co-so-luu-tru-info',
  templateUrl: './co-so-luu-tru-info.component.html',
  styleUrls: ['./co-so-luu-tru-info.component.css']
})
export class CoSoLuuTruInfoComponent implements OnInit {
  PheDuyetDataSource: MatTableDataSource<any>;
  @ViewChild('sortPheDuyet') PheDuyetSort: MatSort;
  @ViewChild('paginatorPheDuyet') PheDuyetPaginator: MatPaginator;
  PheDuyetSearchString: string = environment.InitializationString;

  HoatDongDataSource: MatTableDataSource<any>;
  @ViewChild('sortHoatDong') HoatDongSort: MatSort;
  @ViewChild('paginatorHoatDong') HoatDongPaginator: MatPaginator;
  HoatDongSearchString: string = environment.InitializationString;

  SuKienDataSource: MatTableDataSource<any>;
  @ViewChild('sortSuKien') SuKienSort: MatSort;
  @ViewChild('paginatorSuKien') SuKienPaginator: MatPaginator;
  SuKienSearchString: string = environment.InitializationString;

  TourDataSource: MatTableDataSource<any>;
  @ViewChild('sortTour') TourSort: MatSort;
  @ViewChild('paginatorTour') TourPaginator: MatPaginator;
  TourSearchString: string = environment.InitializationString;

  PhongDataSource: MatTableDataSource<any>;
  @ViewChild('sortPhong') PhongSort: MatSort;
  @ViewChild('paginatorPhong') PhongPaginator: MatPaginator;
  PhongSearchString: string = environment.InitializationString;

  DiaDiemDataSource: MatTableDataSource<any>;
  @ViewChild('sortDiaDiem') DiaDiemSort: MatSort;
  @ViewChild('paginatorDiaDiem') DiaDiemPaginator: MatPaginator;
  DiaDiemSearchString: string = environment.InitializationString;

  NhaHangDataSource: MatTableDataSource<any>;
  @ViewChild('sortNhaHang') NhaHangSort: MatSort;
  @ViewChild('paginatorNhaHang') NhaHangPaginator: MatPaginator;
  NhaHangSearchString: string = environment.InitializationString;

  MonAnDataSource: MatTableDataSource<any>;
  @ViewChild('sortMonAn') MonAnSort: MatSort;
  @ViewChild('paginatorMonAn') MonAnPaginator: MatPaginator;
  MonAnSearchString: string = environment.InitializationString;

  DichVuDataSource: MatTableDataSource<any>;
  @ViewChild('sortDichVu') DichVuSort: MatSort;
  @ViewChild('paginatorDichVu') DichVuPaginator: MatPaginator;
  DichVuSearchString: string = environment.InitializationString;

  HoTroDataSource: MatTableDataSource<any>;
  @ViewChild('sortHoTro') HoTroSort: MatSort;
  @ViewChild('paginatorHoTro') HoTroPaginator: MatPaginator;
  HoTroSearchString: string = environment.InitializationString;

  SocialDataSource: MatTableDataSource<any>;
  @ViewChild('sortSocial') SocialSort: MatSort;
  @ViewChild('paginatorSocial') SocialPaginator: MatPaginator;
  SocialSearchString: string = environment.InitializationString;

  HinhAnhDataSource: MatTableDataSource<any>;
  @ViewChild('HinhAnhSort') HinhAnhSort: MatSort;
  @ViewChild('HinhAnhPaginator') HinhAnhPaginator: MatPaginator;
  HinhAnhSearchString: string = environment.InitializationString;
  HinhAnhFileToUpload: any;
  isCoSoLuuTruHinhAnh: boolean = false;

  VideoDataSource: MatTableDataSource<any>;
  @ViewChild('VideoSort') VideoSort: MatSort;
  @ViewChild('VideoPaginator') VideoPaginator: MatPaginator;
  VideoSearchString: string = environment.InitializationString;
  VideoFileToUpload: any;
  isCoSoLuuTruVideo: boolean = false;

  FileDataSource: MatTableDataSource<any>;
  @ViewChild('FileSort') FileSort: MatSort;
  @ViewChild('FilePaginator') FilePaginator: MatPaginator;
  FileSearchString: string = environment.InitializationString;
  FileFileToUpload: any;
  isCoSoLuuTruFile: boolean = false;

  isShowLoading: boolean = false;
  queryString: string = environment.InitializationString;
  URLSub: string = environment.DomainDestination + "CoSoLuuTruInfo";


  constructor(
    public router: Router,
    public CoSoLuuTruService: CoSoLuuTruService,
    public NotificationService: NotificationService,
    public DanhMucCoSoLuuTruService: DanhMucCoSoLuuTruService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,

    public PheDuyetService: PheDuyetService,
    public CoQuanQuanLyService: CoQuanQuanLyService,
    public ThanhVienService: ThanhVienService,
    public DanhMucPheDuyetService: DanhMucPheDuyetService,

    public CoSoLuuTruHoatDongService: CoSoLuuTruHoatDongService,
    public CoSoLuuTruSuKienService: CoSoLuuTruSuKienService,
    public CoSoLuuTruTourService: CoSoLuuTruTourService,

    public CoSoLuuTruPhongService: CoSoLuuTruPhongService,
    public CoSoLuuTruDiaDiemService: CoSoLuuTruDiaDiemService,
    public CoSoLuuTruNhaHangService: CoSoLuuTruNhaHangService,
    public CoSoLuuTruMonAnService: CoSoLuuTruMonAnService,
    public CoSoLuuTruDichVuService: CoSoLuuTruDichVuService,
    public CoSoLuuTruHoTroService: CoSoLuuTruHoTroService,
    public CoSoLuuTruSocialService: CoSoLuuTruSocialService,

    public CoSoLuuTruHinhAnhService: CoSoLuuTruHinhAnhService,
    public CoSoLuuTruVideoService: CoSoLuuTruVideoService,
    public CoSoLuuTruFileService: CoSoLuuTruFileService,

    public dialog: MatDialog,
  ) {
    this.GetByQueryString();
  }
  ngOnInit(): void {

  }


  ThanhVienGetAllToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienService.GetAllToListAsync().subscribe(
      res => {
        this.ThanhVienService.list = res as ThanhVien[];
        this.ThanhVienService.list = this.ThanhVienService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  CoQuanQuanLyGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoQuanQuanLyService.GetAllToListAsync().subscribe(
      res => {
        this.CoQuanQuanLyService.list = res as CoQuanQuanLy[];
        this.CoQuanQuanLyService.list = this.CoQuanQuanLyService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucPheDuyetGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucPheDuyetService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucPheDuyetService.list = res as DanhMucPheDuyet[];
        this.DanhMucPheDuyetService.list = this.DanhMucPheDuyetService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }


  DanhMucCoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucCoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucCoSoLuuTruService.list = res as DanhMucCoSoLuuTru[];
        this.DanhMucCoSoLuuTruService.list = this.DanhMucCoSoLuuTruService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucTinhThanhGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = res as DanhMucTinhThanh[];
        this.DanhMucTinhThanhService.list = this.DanhMucTinhThanhService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucQuanHuyenGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync(this.CoSoLuuTruService.formData.DanhMucTinhThanhID).subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = res as DanhMucQuanHuyen[];
        this.DanhMucQuanHuyenService.list = this.DanhMucQuanHuyenService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.GetByParentIDToListAsync(this.CoSoLuuTruService.formData.DanhMucQuanHuyenID).subscribe(
      res => {
        this.DanhMucXaPhuongService.list = res as DanhMucXaPhuong[];
        this.DanhMucXaPhuongService.list = this.DanhMucXaPhuongService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetByQueryString() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetByThanhVienIDAsync().subscribe(
      res => {
        this.CoSoLuuTruService.formData = res as CoSoLuuTru;
        if (this.CoSoLuuTruService.formData) {
          this.CoSoLuuTruService.formData.DanhMucTinhThanhID = environment.HoChiMinhCityID;
          this.DanhMucCoSoLuuTruGetAllToListAsync();
          this.DanhMucQuanHuyenGetAllToListAsync();
          if (this.CoSoLuuTruService.formData.ID > 0) {
            this.DanhMucXaPhuongGetAllToListAsync();
            this.PheDuyetGetByParentIDAndEmptyToListAsync();
            this.CoQuanQuanLyGetAllToListAsync();
            this.ThanhVienGetAllToListAsync();
            this.DanhMucPheDuyetGetAllToListAsync();

            this.HoatDongGetByParentIDToListAsync();
            this.SuKienGetByParentIDToListAsync();
            this.TourGetByParentIDToListAsync();

            this.PhongGetByParentIDAndEmptyToListAsync();
            this.DiaDiemGetByParentIDAndEmptyToListAsync();
            this.NhaHangGetByParentIDAndEmptyToListAsync();
            this.MonAnGetByParentIDAndEmptyToListAsync();
            this.DichVuGetByParentIDAndEmptyToListAsync();
            this.HoTroGetByParentIDAndEmptyToListAsync();
            this.SocialGetByParentIDAndEmptyToListAsync();

            this.HinhAnhGetByParentIDToListAsync();
            this.VideoGetByParentIDToListAsync();
            this.FileGetByParentIDToListAsync();
          }
        }
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onSubmit(form: NgForm) {
    this.isShowLoading = true;
    this.CoSoLuuTruService.SaveAsync(this.CoSoLuuTruService.formData).subscribe(
      res => {
        if (this.CoSoLuuTruService.formData.ID == 0) {
        }
        else {
          this.GetByQueryString();
          this.NotificationService.warn(environment.SaveSuccess);
        }
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }


  //Data PheDuyet
  PheDuyetGetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.PheDuyetService.GetByParentIDAndEmptyToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.PheDuyetService.list = res as PheDuyet[];
        this.PheDuyetDataSource = new MatTableDataSource(this.PheDuyetService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.PheDuyetDataSource.sort = this.PheDuyetSort;
        this.PheDuyetDataSource.paginator = this.PheDuyetPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  PheDuyetSearch() {
    if (this.PheDuyetSearchString.length > 0) {
      this.PheDuyetDataSource.filter = this.PheDuyetSearchString.toLowerCase();
    }
    else {
      this.PheDuyetGetByParentIDAndEmptyToListAsync();
    }
  }
  PheDuyetSave(element: PheDuyet) {
    element.ParentID = this.CoSoLuuTruService.formData.ID;
    element.Code = CoSoLuuTru.name;
    this.PheDuyetService.SaveAsync(element).subscribe(
      res => {
        this.PheDuyetSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  PheDuyetDelete(element: PheDuyet) {
    if (confirm(environment.DeleteConfirm)) {
      this.PheDuyetService.RemoveAsync(element.ID).subscribe(
        res => {
          this.PheDuyetSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }

  //Data HoatDong
  HoatDongGetByParentIDToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruHoatDongService.GetByParentIDToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.CoSoLuuTruHoatDongService.list = res as CoSoLuuTruHoatDong[];
        this.HoatDongDataSource = new MatTableDataSource(this.CoSoLuuTruHoatDongService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.HoatDongDataSource.sort = this.HoatDongSort;
        this.HoatDongDataSource.paginator = this.HoatDongPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  HoatDongSearch() {
    if (this.HoatDongSearchString.length > 0) {
      this.HoatDongDataSource.filter = this.HoatDongSearchString.toLowerCase();
    }
    else {
      this.HoatDongGetByParentIDToListAsync();
    }
  }
  HoatDongSave(element: CoSoLuuTruHoatDong) {
    element.ParentID = this.CoSoLuuTruService.formData.ID;
    this.CoSoLuuTruHoatDongService.SaveAsync(element).subscribe(
      res => {
        this.HoatDongSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  HoatDongDelete(element: CoSoLuuTruHoatDong) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruHoatDongService.RemoveAsync(element.ID).subscribe(
        res => {
          this.HoatDongSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }

  HoatDongAdd(ID: any) {
    this.CoSoLuuTruHoatDongService.GetByIDAsync(ID).subscribe(
      res => {
        this.CoSoLuuTruHoatDongService.formData = res as CoSoLuuTruHoatDong;
        this.CoSoLuuTruHoatDongService.formData.ParentID = this.CoSoLuuTruService.formData.ID;
        if (ID == 0) {
          this.CoSoLuuTruHoatDongService.formData.NgayBatDau = new Date();
          this.CoSoLuuTruHoatDongService.formData.NgayKetThuc = new Date();
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CoSoLuuTruHoatDongDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.HoatDongGetByParentIDToListAsync();
        });
      },
      err => {
      }
    );
  }

  //Data SuKien
  SuKienGetByParentIDToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruSuKienService.GetByParentIDToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.CoSoLuuTruSuKienService.list = res as CoSoLuuTruSuKien[];
        this.SuKienDataSource = new MatTableDataSource(this.CoSoLuuTruSuKienService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.SuKienDataSource.sort = this.SuKienSort;
        this.SuKienDataSource.paginator = this.SuKienPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  SuKienSearch() {
    if (this.SuKienSearchString.length > 0) {
      this.SuKienDataSource.filter = this.SuKienSearchString.toLowerCase();
    }
    else {
      this.SuKienGetByParentIDToListAsync();
    }
  }
  SuKienSave(element: CoSoLuuTruSuKien) {
    element.ParentID = this.CoSoLuuTruService.formData.ID;
    this.CoSoLuuTruSuKienService.SaveAsync(element).subscribe(
      res => {
        this.SuKienSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  SuKienDelete(element: CoSoLuuTruSuKien) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruSuKienService.RemoveAsync(element.ID).subscribe(
        res => {
          this.SuKienSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  SuKienAdd(ID: any) {
    this.CoSoLuuTruSuKienService.GetByIDAsync(ID).subscribe(
      res => {
        this.CoSoLuuTruSuKienService.formData = res as CoSoLuuTruSuKien;
        this.CoSoLuuTruSuKienService.formData.ParentID = this.CoSoLuuTruService.formData.ID;
        if (ID == 0) {
          this.CoSoLuuTruSuKienService.formData.NgayBatDau = new Date();
          this.CoSoLuuTruSuKienService.formData.NgayKetThuc = new Date();
        }

        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CoSoLuuTruSuKienDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.SuKienGetByParentIDToListAsync();
        });
      },
      err => {
      }
    );
  }
  //Data Tour
  TourGetByParentIDToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruTourService.GetByParentIDToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.CoSoLuuTruTourService.list = res as CoSoLuuTruTour[];
        this.TourDataSource = new MatTableDataSource(this.CoSoLuuTruTourService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.TourDataSource.sort = this.TourSort;
        this.TourDataSource.paginator = this.TourPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  TourSearch() {
    if (this.TourSearchString.length > 0) {
      this.TourDataSource.filter = this.TourSearchString.toLowerCase();
    }
    else {
      this.TourGetByParentIDToListAsync();
    }
  }
  TourSave(element: CoSoLuuTruTour) {
    element.ParentID = this.CoSoLuuTruService.formData.ID;
    this.CoSoLuuTruTourService.SaveAsync(element).subscribe(
      res => {
        this.TourSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  TourDelete(element: CoSoLuuTruTour) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruTourService.RemoveAsync(element.ID).subscribe(
        res => {
          this.TourSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  TourAdd(ID: any) {
    this.CoSoLuuTruTourService.GetByIDAsync(ID).subscribe(
      res => {
        this.CoSoLuuTruTourService.formData = res as CoSoLuuTruTour;
        this.CoSoLuuTruTourService.formData.ParentID = this.CoSoLuuTruService.formData.ID;
        if (ID == 0) {
          this.CoSoLuuTruTourService.formData.NgayBatDau = new Date();
          this.CoSoLuuTruTourService.formData.NgayKetThuc = new Date();
        }
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = environment.DialogConfigWidth;
        dialogConfig.data = { ID: ID };
        const dialog = this.dialog.open(CoSoLuuTruTourDetailComponent, dialogConfig);
        dialog.afterClosed().subscribe(() => {
          this.TourGetByParentIDToListAsync();
        });
      },
      err => {
      }
    );
  }

  //Data Phong
  PhongGetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruPhongService.GetByParentIDAndEmptyToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.CoSoLuuTruPhongService.list = res as CoSoLuuTruPhong[];
        this.PhongDataSource = new MatTableDataSource(this.CoSoLuuTruPhongService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.PhongDataSource.sort = this.PhongSort;
        this.PhongDataSource.paginator = this.PhongPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  PhongSearch() {
    if (this.PhongSearchString.length > 0) {
      this.PhongDataSource.filter = this.PhongSearchString.toLowerCase();
    }
    else {
      this.PhongGetByParentIDAndEmptyToListAsync();
    }
  }
  PhongSave(element: CoSoLuuTruPhong) {
    element.ParentID = this.CoSoLuuTruService.formData.ID;

    this.CoSoLuuTruPhongService.SaveAsync(element).subscribe(
      res => {
        this.PhongSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  PhongDelete(element: CoSoLuuTruPhong) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruPhongService.RemoveAsync(element.ID).subscribe(
        res => {
          this.PhongSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }

  //Data DiaDiem
  DiaDiemGetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruDiaDiemService.GetByParentIDAndEmptyToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.CoSoLuuTruDiaDiemService.list = res as CoSoLuuTruDiaDiem[];
        this.DiaDiemDataSource = new MatTableDataSource(this.CoSoLuuTruDiaDiemService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.DiaDiemDataSource.sort = this.DiaDiemSort;
        this.DiaDiemDataSource.paginator = this.DiaDiemPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DiaDiemSearch() {
    if (this.DiaDiemSearchString.length > 0) {
      this.DiaDiemDataSource.filter = this.DiaDiemSearchString.toLowerCase();
    }
    else {
      this.DiaDiemGetByParentIDAndEmptyToListAsync();
    }
  }
  DiaDiemSave(element: CoSoLuuTruDiaDiem) {
    element.ParentID = this.CoSoLuuTruService.formData.ID;
    this.CoSoLuuTruDiaDiemService.SaveAsync(element).subscribe(
      res => {
        this.DiaDiemSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  DiaDiemDelete(element: CoSoLuuTruDiaDiem) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruDiaDiemService.RemoveAsync(element.ID).subscribe(
        res => {
          this.DiaDiemSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  //Data NhaHang
  NhaHangGetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruNhaHangService.GetByParentIDAndEmptyToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.CoSoLuuTruNhaHangService.list = res as CoSoLuuTruNhaHang[];
        this.NhaHangDataSource = new MatTableDataSource(this.CoSoLuuTruNhaHangService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.NhaHangDataSource.sort = this.NhaHangSort;
        this.NhaHangDataSource.paginator = this.NhaHangPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  NhaHangSearch() {
    if (this.NhaHangSearchString.length > 0) {
      this.NhaHangDataSource.filter = this.NhaHangSearchString.toLowerCase();
    }
    else {
      this.NhaHangGetByParentIDAndEmptyToListAsync();
    }
  }
  NhaHangSave(element: CoSoLuuTruNhaHang) {
    element.ParentID = this.CoSoLuuTruService.formData.ID;

    this.CoSoLuuTruNhaHangService.SaveAsync(element).subscribe(
      res => {
        this.NhaHangSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  NhaHangDelete(element: CoSoLuuTruNhaHang) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruNhaHangService.RemoveAsync(element.ID).subscribe(
        res => {
          this.NhaHangSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }

  //Data MonAn
  MonAnGetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruMonAnService.GetByParentIDAndEmptyToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.CoSoLuuTruMonAnService.list = res as CoSoLuuTruMonAn[];
        this.MonAnDataSource = new MatTableDataSource(this.CoSoLuuTruMonAnService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.MonAnDataSource.sort = this.MonAnSort;
        this.MonAnDataSource.paginator = this.MonAnPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  MonAnSearch() {
    if (this.MonAnSearchString.length > 0) {
      this.MonAnDataSource.filter = this.MonAnSearchString.toLowerCase();
    }
    else {
      this.MonAnGetByParentIDAndEmptyToListAsync();
    }
  }
  MonAnSave(element: CoSoLuuTruMonAn) {
    element.ParentID = this.CoSoLuuTruService.formData.ID;

    this.CoSoLuuTruMonAnService.SaveAsync(element).subscribe(
      res => {
        this.MonAnSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  MonAnDelete(element: CoSoLuuTruMonAn) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruMonAnService.RemoveAsync(element.ID).subscribe(
        res => {
          this.MonAnSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }

  //Data DichVu
  DichVuGetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruDichVuService.GetByParentIDAndEmptyToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.CoSoLuuTruDichVuService.list = res as CoSoLuuTruDichVu[];
        this.DichVuDataSource = new MatTableDataSource(this.CoSoLuuTruDichVuService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.DichVuDataSource.sort = this.DichVuSort;
        this.DichVuDataSource.paginator = this.DichVuPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DichVuSearch() {
    if (this.DichVuSearchString.length > 0) {
      this.DichVuDataSource.filter = this.DichVuSearchString.toLowerCase();
    }
    else {
      this.DichVuGetByParentIDAndEmptyToListAsync();
    }
  }
  DichVuSave(element: CoSoLuuTruDichVu) {
    element.ParentID = this.CoSoLuuTruService.formData.ID;

    this.CoSoLuuTruDichVuService.SaveAsync(element).subscribe(
      res => {
        this.DichVuSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  DichVuDelete(element: CoSoLuuTruDichVu) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruDichVuService.RemoveAsync(element.ID).subscribe(
        res => {
          this.DichVuSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }

  //Data HoTro
  HoTroGetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruHoTroService.GetByParentIDAndEmptyToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.CoSoLuuTruHoTroService.list = res as CoSoLuuTruHoTro[];
        this.HoTroDataSource = new MatTableDataSource(this.CoSoLuuTruHoTroService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.HoTroDataSource.sort = this.HoTroSort;
        this.HoTroDataSource.paginator = this.HoTroPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  HoTroSearch() {
    if (this.HoTroSearchString.length > 0) {
      this.HoTroDataSource.filter = this.HoTroSearchString.toLowerCase();
    }
    else {
      this.HoTroGetByParentIDAndEmptyToListAsync();
    }
  }
  HoTroSave(element: CoSoLuuTruHoTro) {
    element.ParentID = this.CoSoLuuTruService.formData.ID;

    this.CoSoLuuTruHoTroService.SaveAsync(element).subscribe(
      res => {
        this.HoTroSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  HoTroDelete(element: CoSoLuuTruHoTro) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruHoTroService.RemoveAsync(element.ID).subscribe(
        res => {
          this.HoTroSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }

  //Data Social
  SocialGetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruSocialService.GetByParentIDAndEmptyToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.CoSoLuuTruSocialService.list = res as CoSoLuuTruSocial[];
        this.SocialDataSource = new MatTableDataSource(this.CoSoLuuTruSocialService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.SocialDataSource.sort = this.SocialSort;
        this.SocialDataSource.paginator = this.SocialPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  SocialSearch() {
    if (this.SocialSearchString.length > 0) {
      this.SocialDataSource.filter = this.SocialSearchString.toLowerCase();
    }
    else {
      this.SocialGetByParentIDAndEmptyToListAsync();
    }
  }
  SocialSave(element: CoSoLuuTruSocial) {
    element.ParentID = this.CoSoLuuTruService.formData.ID;

    this.CoSoLuuTruSocialService.SaveAsync(element).subscribe(
      res => {
        this.SocialSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  SocialDelete(element: CoSoLuuTruSocial) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruSocialService.RemoveAsync(element.ID).subscribe(
        res => {
          this.SocialSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }

  //hinh anh
  HinhAnhGetByParentIDToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruHinhAnhService.GetByParentIDToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.CoSoLuuTruHinhAnhService.list = res as CoSoLuuTruHinhAnh[];
        this.HinhAnhDataSource = new MatTableDataSource(this.CoSoLuuTruHinhAnhService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.HinhAnhDataSource.sort = this.HinhAnhSort;
        this.HinhAnhDataSource.paginator = this.HinhAnhPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  HinhAnhSearch() {
    if (this.HinhAnhSearchString.length > 0) {
      this.HinhAnhDataSource.filter = this.HinhAnhSearchString.toLowerCase();
    }
    else {
      this.HinhAnhGetByParentIDToListAsync();
    }
  }
  HinhAnhChangeFiles(files: FileList) {
    if (files) {
      this.HinhAnhFileToUpload = files;
      this.isCoSoLuuTruHinhAnh = true;
    }
  }
  HinhAnhSubmitFiles() {
    if (this.HinhAnhFileToUpload != null && this.HinhAnhFileToUpload.length > 0) {
      let fileToUpload: FileList;
      fileToUpload = this.HinhAnhFileToUpload;
      this.isShowLoading = true;
      this.CoSoLuuTruHinhAnhService.formData.ParentID = this.CoSoLuuTruService.formData.ID;
      this.CoSoLuuTruHinhAnhService.SaveAndUploadHinhAnhsAsync(this.CoSoLuuTruHinhAnhService.formData, fileToUpload).subscribe(
        res => {
          this.HinhAnhSearch();
          this.isShowLoading = false;
          this.NotificationService.warn(environment.UploadSuccess);
        },
        err => {
          this.isShowLoading = false;
          this.NotificationService.warn(environment.UploadNotSuccess);
        }
      );
    }
  }
  HinhAnhDelete(element: CoSoLuuTruHinhAnh) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruHinhAnhService.RemoveAsync(element.ID).subscribe(
        res => {
          this.HinhAnhSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  //video
  VideoGetByParentIDToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruVideoService.GetByParentIDToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.CoSoLuuTruVideoService.list = res as CoSoLuuTruVideo[];
        this.VideoDataSource = new MatTableDataSource(this.CoSoLuuTruVideoService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.VideoDataSource.sort = this.VideoSort;
        this.VideoDataSource.paginator = this.VideoPaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  VideoSearch() {
    if (this.VideoSearchString.length > 0) {
      this.VideoDataSource.filter = this.VideoSearchString.toLowerCase();
    }
    else {
      this.VideoGetByParentIDToListAsync();
    }
  }
  VideoChangeFiles(files: FileList) {
    if (files) {
      this.VideoFileToUpload = files;
      this.isCoSoLuuTruVideo = true;
    }
  }
  VideoSubmitFiles() {
    if (this.VideoFileToUpload != null && this.VideoFileToUpload.length > 0) {
      let fileToUpload: FileList;
      fileToUpload = this.VideoFileToUpload;
      this.isShowLoading = true;
      this.CoSoLuuTruVideoService.formData.ParentID = this.CoSoLuuTruService.formData.ID;
      this.CoSoLuuTruVideoService.SaveAndUploadVideosAsync(this.CoSoLuuTruVideoService.formData, fileToUpload).subscribe(
        res => {
          this.VideoSearch();
          this.isShowLoading = false;
          this.NotificationService.warn(environment.UploadSuccess);
        },
        err => {
          this.isShowLoading = false;
          this.NotificationService.warn(environment.UploadNotSuccess);
        }
      );
    }

  }
  VideoDelete(element: CoSoLuuTruVideo) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruVideoService.RemoveAsync(element.ID).subscribe(
        res => {
          this.VideoSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  //video
  FileGetByParentIDToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruFileService.GetByParentIDToListAsync(this.CoSoLuuTruService.formData.ID).subscribe(
      res => {
        this.CoSoLuuTruFileService.list = res as CoSoLuuTruFile[];
        this.FileDataSource = new MatTableDataSource(this.CoSoLuuTruFileService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.FileDataSource.sort = this.FileSort;
        this.FileDataSource.paginator = this.FilePaginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  FileSearch() {
    if (this.FileSearchString.length > 0) {
      this.FileDataSource.filter = this.FileSearchString.toLowerCase();
    }
    else {
      this.FileGetByParentIDToListAsync();
    }
  }
  FileChangeFiles(files: FileList) {
    if (files) {
      this.FileFileToUpload = files;
      this.isCoSoLuuTruFile = true;
    }
  }
  FileSubmitFiles() {
    if (this.FileFileToUpload != null && this.FileFileToUpload.length > 0) {
      let fileToUpload: FileList;
      fileToUpload = this.FileFileToUpload;
      this.isShowLoading = true;
      this.CoSoLuuTruFileService.formData.ParentID = this.CoSoLuuTruService.formData.ID;
      this.CoSoLuuTruFileService.SaveAndUploadFilesAsync(this.CoSoLuuTruFileService.formData, fileToUpload).subscribe(
        res => {
          this.FileSearch();
          this.isShowLoading = false;
          this.NotificationService.warn(environment.UploadSuccess);
        },
        err => {
          this.isShowLoading = false;
          this.NotificationService.warn(environment.UploadNotSuccess);
        }
      );
    }
  }
  FileDelete(element: CoSoLuuTruFile) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruFileService.RemoveAsync(element.ID).subscribe(
        res => {
          this.FileSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }


}
