import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';
import { DanhMucCoSoLuuTruService } from 'src/app/shared/DanhMucCoSoLuuTru.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DownloadService } from 'src/app/shared/Download.service';


@Component({
  selector: 'app-co-so-luu-tru-diem-du-lich',
  templateUrl: './co-so-luu-tru-diem-du-lich.component.html',
  styleUrls: ['./co-so-luu-tru-diem-du-lich.component.css']
})
export class CoSoLuuTruDiemDuLichComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  queryString: string = environment.InitializationString;
  parentID:number=environment.DiemDuLichID;
  URLSub: string = environment.DomainDestination + "CoSoLuuTruInfo";
  quanHuyenID : number = environment.InitializationNumber;
  xaPhuongID : number = environment.InitializationNumber;

  constructor(
    public router: Router,
    public CoSoLuuTruService: CoSoLuuTruService,
    public DanhMucCoSoLuuTruService: DanhMucCoSoLuuTruService,
    public NotificationService: NotificationService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DownloadService: DownloadService,
  ) {

  }

  ngOnInit(): void {
    this.DanhMucQuanHuyenGetAllToListAsync();
    this.GetByParentID_QuanHuyenID_XaPhuongIDAsync();
    this.GetByID();
  }

  GetByID() {
    this.isShowLoading = true;
    this.DanhMucCoSoLuuTruService.GetByID(this.parentID).subscribe(res => {
      this.DanhMucCoSoLuuTruService.formData = res as CoSoLuuTru;
      if (this.DanhMucCoSoLuuTruService.formData) {
        
      }
    },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  DanhMucQuanHuyenGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.GetByThanhVienIDToListAsync().subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = res as DanhMucQuanHuyen[];
        this.DanhMucQuanHuyenService.list = this.DanhMucQuanHuyenService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  DanhMucXaPhuongGetAllToListAsync() {
    this.GetByParentID_QuanHuyenID_XaPhuongIDAsync();
    this.isShowLoading = true;
    this.DanhMucXaPhuongService.GetByParentIDAndThanhVienIDToListAsync(this.quanHuyenID).subscribe(
      res => {
        this.DanhMucXaPhuongService.list = res as DanhMucXaPhuong[];
        this.DanhMucXaPhuongService.list = this.DanhMucXaPhuongService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  // GetByParentIDToListAsync() {
  //   this.isShowLoading = true;
  //   this.CoSoLuuTruService.GetByParentIDToListAsync(this.parentID).subscribe(res => {
  //     this.CoSoLuuTruService.list = res as CoSoLuuTru[];
  //     this.CoSoLuuTruService.list =this.CoSoLuuTruService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        
  //     this.dataSource = new MatTableDataSource(this.CoSoLuuTruService.list);
  //     this.dataSource.sort = this.sort;
  //     this.dataSource.paginator = this.paginator;
  //     this.isShowLoading = false;
  //   },
  //     err => {
  //       this.isShowLoading = false;
  //     }
  //   );
  // }

  GetByParentID_QuanHuyenID_XaPhuongIDAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetByParentID_QuanHuyenID_XaPhuongIDAsync(this.parentID,this.quanHuyenID,this.xaPhuongID).subscribe(res => {
      this.CoSoLuuTruService.list = res as CoSoLuuTru[];
      this.CoSoLuuTruService.list =this.CoSoLuuTruService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        
      this.dataSource = new MatTableDataSource(this.CoSoLuuTruService.list);
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isShowLoading = false;
    },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetByParentID_QuanHuyenID_XaPhuongIDAsync();
    }
  }
  onSave(element: CoSoLuuTru) {
    element.ParentID= this.parentID;
    this.CoSoLuuTruService.SaveAsync(element).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: CoSoLuuTru) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  onDownloadExcel() {
    this.isShowLoading = true;
    this.DownloadService.DanhSachCoSoLuuTruToExcelAsync(environment.DiemDuLichID).subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
}