import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DanhMucVanBan } from 'src/app/shared/DanhMucVanBan.model';
import { DanhMucVanBanService } from 'src/app/shared/DanhMucVanBan.service';

@Component({
  selector: 'app-danh-muc-van-ban',
  templateUrl: './danh-muc-van-ban.component.html',
  styleUrls: ['./danh-muc-van-ban.component.css']
})
export class DanhMucVanBanComponent implements OnInit {

  dataSource: MatTableDataSource<any>
  ;
@ViewChild(MatSort) sort: MatSort;
@ViewChild(MatPaginator) paginator: MatPaginator;
isShowLoading: boolean = false;
searchString: string = environment.InitializationString;
constructor(
  public DanhMucVanBanService: DanhMucVanBanService,
  public NotificationService: NotificationService,
) { }

ngOnInit(): void {
  this.GetAllAndEmptyToListAsync();
}
GetAllAndEmptyToListAsync() {
  this.isShowLoading = true;
  this.DanhMucVanBanService.GetAllAndEmptyToListAsync().subscribe(
    res => {
      this.DanhMucVanBanService.list = res as DanhMucVanBan[];
      this.dataSource = new MatTableDataSource(this.DanhMucVanBanService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
      this.dataSource.sort = this.sort;
      this.dataSource.paginator = this.paginator;
      this.isShowLoading = false;
    },
    err => {
      this.isShowLoading = false;
    }
  );
}
onSearch() {
  if (this.searchString.length > 0) {
    this.dataSource.filter = this.searchString.toLowerCase();
  }
  else {
    this.GetAllAndEmptyToListAsync();
  }
}
onSave(element: DanhMucVanBan) {
  this.DanhMucVanBanService.SaveAsync(element).subscribe(
    res => {
      this.onSearch();
      this.NotificationService.warn(environment.SaveSuccess);
    },
    err => {
      this.NotificationService.warn(environment.SaveNotSuccess);
    }
  );
}
onDelete(element: DanhMucVanBan) {
  if (confirm(environment.DeleteConfirm)) {
    this.DanhMucVanBanService.RemoveAsync(element.ID).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.DeleteSuccess);
      },
      err => {
        this.NotificationService.warn(environment.DeleteNotSuccess);
      }
    );
  }
}
}