import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoQuanQuanLy } from 'src/app/shared/CoQuanQuanLy.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class CoQuanQuanLyService extends BaseService{
    displayColumns: string[] = ['Name','MaSoThue','DienThoai','Email','Active', 'Save']; 
    list: CoQuanQuanLy[] | undefined;
    formData!: CoQuanQuanLy;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "CoQuanQuanLy";
    }

    GetByIDStringAsync(ID: string) {
        let url = this.aPIURL + this.controller + '/GetByIDStringAsync';
        const formUpload: FormData = new FormData();        
        formUpload.append('ID', JSON.stringify(ID));        
        return this.httpClient.post(url, formUpload);
    }
}

