<div class="vertical-layout page-header-light vertical-menu-collapsible vertical-gradient-menu 2-column"
      data-open="click" data-menu="vertical-menu-nav-dark" data-col="2-column">
      <header class="page-topbar" id="header">
            <div class="navbar navbar-fixed">
                  <nav class="navbar-main navbar-color nav-collapsible sideNav-lock navbar-light">
                        <div class="nav-wrapper">
                              <div class="header-search-wrapper hide-on-med-and-down">
                                    <input disabled class="header-search-input z-depth-2" type="text" name="Search"
                                          placeholder="{{DownloadService.PageTitle()}} | Application for tourists">
                              </div>
                              <ul class="navbar-list right">
                                    <li><a class="waves-effect waves-block waves-light notification-button"
                                                href="javascript:void(0);" data-target="notifications-dropdown"><i
                                                      class="material-icons">notifications_none<small
                                                            class="notification-badge"
                                                            *ngIf="ThongBaoService.list">{{ThongBaoService.list.length}}</small></i></a>
                                    </li>
                                    <li><a class="waves-effect waves-block waves-light profile-button"
                                                href="javascript:void(0);" data-target="profile-dropdown"><span
                                                      class="avatar-status avatar-online"><img
                                                            src="{{ThanhVienService.formDataLogin.AvatarURL}}"
                                                            alt="{{ThanhVienService.formDataLogin.HoTen}}"
                                                            title="{{ThanhVienService.formDataLogin.HoTen}}"><i></i></span></a>
                                    </li>
                                    <li><a class="waves-effect waves-block waves-light sidenav-trigger" href="#"
                                                data-target="slide-out-right"><i
                                                      class="material-icons">format_indent_increase</i></a></li>
                              </ul>
                              <ul class="dropdown-content" id="translation-dropdown">
                              </ul>
                              <ul class="dropdown-content" id="notifications-dropdown">
                                    <li>
                                          <h6>Notifications<span class="new badge"
                                                      *ngIf="ThongBaoService.list">{{ThongBaoService.list.length}}</span>
                                          </h6>
                                    </li>
                                    <li class="divider"></li>
                                    <li *ngFor="let element of ThongBaoService.list;">
                                          <a class="grey-text text-darken-2" href="{{element.URL}}"><span
                                                      class="{{element.CSSClass}}">{{element.Icon}}</span>
                                                {{element.Name}}</a>
                                          <time class="media-meta"
                                                datetime="{{element.DateCreated}}">{{element.DateCreated}}</time>
                                    </li>
                              </ul>
                              <ul class="dropdown-content" id="profile-dropdown">
                                    <li><a class="grey-text text-darken-1" href="{{domainName}}ThanhVienThongTin"><i
                                                      class="material-icons">person_outline</i> Account</a></li>
                                    <li><a class="grey-text text-darken-1" href="{{domainName}}ThanhVienQuenMatKhau"><i
                                                      class="material-icons">lock_outline</i> Change Password</a></li>
                                    <li><a class="grey-text text-darken-1" (click)="onLogout()"><i
                                                      class="material-icons">keyboard_tab</i> Logout</a></li>
                              </ul>
                        </div>
                        <nav class="display-none search-sm">
                              <div class="nav-wrapper">
                              </div>
                        </nav>
                  </nav>
            </div>
      </header>
      <aside class="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-dark gradient-45deg-deep-purple-blue sidenav-gradient sidenav-active-rounded">
            <div class="brand-sidebar">
                  <h1 class="logo-wrapper"><a class="brand-logo darken-1" href="#" style="color:#009f3c;"><span
                                    class="logo-text hide-on-med-and-down" style="color:#009f3c;">Visit HCMC</span></a><a
                              class="navbar-toggler" href="#"><i class="material-icons">radio_button_checked</i></a>
                  </h1>
            </div>
            <ul class="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow"
                  id="slide-out" data-menu="menu-navigation" data-collapsible="accordion">
                  <li class="bold" style="display: flex; align-items: center; justify-content: center;"><img
                        class="responsive-img" src="/assets/image/logo.png" width="50%"
                        alt="{{DownloadService.PageTitle()}}" title="{{DownloadService.PageTitle()}}">
            </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}DiaDiemDaChieu"><i
                                    class="material-icons">add_location</i><span class="menu-title" data-i18n="">Discover 360</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}DatPhongTrucTuyen"><i
                                    class="material-icons">business</i><span class="menu-title" data-i18n="">Online
                                    booking</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}ThanhVienThongTin"><i
                                    class="material-icons">person_outline</i><span class="menu-title"
                                    data-i18n="">Account</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}ThanhVienQuenMatKhau"><i
                                    class="material-icons">lock_outline</i><span class="menu-title" data-i18n="">Change
                                    Password</span></a>
                  </li>
            </ul>
            <div class="navigation-background"></div><a
                  class="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-effect waves-light hide-on-large-only"
                  href="#" data-target="slide-out"><i class="material-icons">menu</i></a>
      </aside>
      <div id="main">
            <router-outlet></router-outlet>
      </div>
</div>
<br />
<br />
<br />