import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTruDiaDiem } from 'src/app/shared/CoSoLuuTruDiaDiem.model';
import { CoSoLuuTruDiaDiemService } from 'src/app/shared/CoSoLuuTruDiaDiem.service';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';

@Component({
  selector: 'app-dia-diem',
  templateUrl: './dia-diem.component.html',
  styleUrls: ['./dia-diem.component.css']
})
export class DiaDiemComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;

  constructor(
    public CoSoLuuTruDiaDiemService: CoSoLuuTruDiaDiemService,
    public CoSoLuuTruService: CoSoLuuTruService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.CoSoLuuTruGetAllToListAsync();
    this.GetByParentIDAndEmptyToListAsync();
  }

  CoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.CoSoLuuTruService.list = res as CoSoLuuTru[];
        this.CoSoLuuTruService.list= this.CoSoLuuTruService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }


  GetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruDiaDiemService.GetByParentIDAndEmptyToListAsync(this.parentID).subscribe(
      res => {
        this.CoSoLuuTruDiaDiemService.list = res as CoSoLuuTruDiaDiem[];
        this.CoSoLuuTruDiaDiemService.list= this.CoSoLuuTruDiaDiemService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        if(this.CoSoLuuTruDiaDiemService.list.length>0){
          this.CoSoLuuTruDiaDiemService.list[0].ID=environment.InitializationNumber;
        }
        this.dataSource = new MatTableDataSource(this.CoSoLuuTruDiaDiemService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetByParentIDAndEmptyToListAsync();
    }
  }
  onSave(element: CoSoLuuTruDiaDiem) {
    element.ParentID=this.parentID;
    this.CoSoLuuTruDiaDiemService.SaveAsync(element).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: CoSoLuuTruDiaDiem) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruDiaDiemService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
}