import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { NotificationService } from 'src/app/shared/Notification.service';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucXaPhuong } from 'src/app/shared/DanhMucXaPhuong.model';
import { DanhMucXaPhuongService } from 'src/app/shared/DanhMucXaPhuong.service';
import { DanhMucQuocGia } from 'src/app/shared/DanhMucQuocGia.model';
import { DanhMucQuocGiaService } from 'src/app/shared/DanhMucQuocGia.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';

@Component({
  selector: 'app-thanh-vien-thong-tin',
  templateUrl: './thanh-vien-thong-tin.component.html',
  styleUrls: ['./thanh-vien-thong-tin.component.css']
})
export class ThanhVienThongTinComponent implements OnInit {

  isShowLoading: boolean = false;
  queryString: string = environment.InitializationString;
  constructor(
    public router: Router,
    public ThanhVienService: ThanhVienService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucXaPhuongService: DanhMucXaPhuongService,
    public DanhMucQuocGiaService: DanhMucQuocGiaService,
    public DanhMucThanhVienService: DanhMucThanhVienService,
    public NotificationService: NotificationService,
  ) {
    this.GetByQueryString();
  }

  ngOnInit(): void {

  }
  DanhMucQuocGiaGetToListAsync() {
    this.DanhMucQuocGiaService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucQuocGiaService.list = (res as DanhMucQuocGia[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
      },
      err => {
      }
    );
  }
  DanhMucThanhVienGetToListAsync() {
    this.DanhMucThanhVienService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucThanhVienService.list = (res as DanhMucThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
      },
      err => {
      }
    );
  }
  DanhMucTinhThanhGetToListAsync() {
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = (res as DanhMucTinhThanh[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucQuanHuyenGetToListAsync();
      },
      err => {
      }
    );
  }
  DanhMucQuanHuyenGetToListAsync() {
    this.DanhMucQuanHuyenService.GetByParentIDToListAsync(this.ThanhVienService.formDataLogin.DanhMucTinhThanhID).subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = (res as DanhMucQuanHuyen[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.DanhMucXaPhuongGetToListAsync();
      },
      err => {
      }
    );
  }
  DanhMucXaPhuongGetToListAsync() {
    this.DanhMucXaPhuongService.GetByParentIDToListAsync(this.ThanhVienService.formDataLogin.DanhMucQuanHuyenID).subscribe(
      res => {
        this.DanhMucXaPhuongService.list = (res as DanhMucXaPhuong[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
      },
      err => {
      }
    );
  }
  GetByQueryString() {
    if (this.ThanhVienService.formDataLogin) {
      if (this.ThanhVienService.formDataLogin.ID) {
        if (this.ThanhVienService.formDataLogin.ID > 0) {
          this.ThanhVienService.GetByIDAsync(this.ThanhVienService.formDataLogin.ID).subscribe(
            res => {
              this.ThanhVienService.formDataLogin = res as ThanhVien;
              this.DanhMucQuocGiaGetToListAsync();
              this.DanhMucThanhVienGetToListAsync();
              this.DanhMucTinhThanhGetToListAsync();
              this.isShowLoading = false;
            },
            err => {
              this.isShowLoading = false;
            }
          );

        }
      }
    }
  }
  onSubmit(form: NgForm) {
    this.isShowLoading = true;
    this.ThanhVienService.SaveAndUploadFileAsync(form.value, this.fileToUpload).subscribe(
      res => {
        this.ThanhVienService.formDataLogin = res as ThanhVien;
        this.NotificationService.warn(environment.SaveSuccess);
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  fileToUpload: any;
  fileToUpload0: File = null;
  changeImage(files: FileList) {
    if (files) {
      this.fileToUpload = files;
      this.fileToUpload0 = files.item(0);
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.ThanhVienService.formDataLogin.AvatarURL = event.target.result;
      };
      reader.readAsDataURL(this.fileToUpload0);
    }
  }
}
