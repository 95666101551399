export class Base {
    ID?: number;
    ParentID?: number;
    CreatedDate?: Date;
    CreatedMembershipID?: number;
    LastUpdatedDate?: Date;
    LastUpdatedMembershipID?: number;
    RowVersion?: number;
    SortOrder?: number;
    Active?: boolean;
    Name?: string;
    Code?: string;
    Note?: string;   
}

