<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <div class="col s12 m12 l9">
        <h5 class="card-title"><b>Chương trình quảng bá xúc tiến</b></h5>
    </div>
    <div class="col s12 m12 l3">
        <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
        <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
            <i class="material-icons">close</i>
        </a>
    </div>
    <input name="ID" [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.ID" type="hidden">
    <input name="ParentID" [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.ParentID" type="hidden">
    <input name="SortOrder" [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.SortOrder" type="hidden">
    <div class="col s12 m12 l6">
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Chương trình</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Tên chương trình" name="Name" [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.Name"
                    type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Note">Tình trạng</label>
            </div>
        </div>
        <div class="col s8 m8 l8 mt-2 mb-3">
            <div class="input-field">
                <p>
                    <label>
                        <input type="checkbox" class="filled-in" name="Active"
                            [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.Active" />
                        <span>Phê duyệt / Chưa phê duyệt</span>
                    </label>
                </p>
            </div>
        </div>
    </div>
    
    <div class="col s12 m12 l6">
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Bắt đầu</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input name="NgayBatDau" style="text-align: right; font-weight: bold; width: 100%;"
                    [ngModel]="ChuongTrinhQuangBaXucTienService.formData.NgayBatDau | date:'yyyy-MM-dd'"
                    (ngModelChange)="NgayBatDauChange($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                    type="date">

            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Kết thúc</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input name="NgayKetThuc" style="text-align: right; font-weight: bold; width: 100%;"
                    [ngModel]="ChuongTrinhQuangBaXucTienService.formData.NgayKetThuc | date:'yyyy-MM-dd'"
                    (ngModelChange)="NgayKetThucChange($event)" [ngModelOptions]="{ updateOn: 'blur' }"
                    type="date">

            </div>
        </div>
    </div>
    
    <div class="col s12 m12 l12">
        <ck-editor [config]="{'height': 400}" name="Note" [(ngModel)]="ChuongTrinhQuangBaXucTienService.formData.Note"
            skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>