import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoQuanQuanLy } from 'src/app/shared/CoQuanQuanLy.model';
import { CoQuanQuanLyService } from 'src/app/shared/CoQuanQuanLy.service';

@Component({
  selector: 'app-co-quan-quan-ly',
  templateUrl: './co-quan-quan-ly.component.html',
  styleUrls: ['./co-quan-quan-ly.component.css']
})
export class CoQuanQuanLyComponent implements OnInit {

  dataSource: MatTableDataSource<any>;  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;

  URLSub: string = environment.DomainDestination + "CoQuanQuanLyInfo";
  constructor(
    public CoQuanQuanLyService: CoQuanQuanLyService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.GetAllToListAsync();
  }
  GetAllToListAsync() {
    this.isShowLoading = true;
    this.CoQuanQuanLyService.GetAllToListAsync().subscribe(
      res => {
        this.CoQuanQuanLyService.list = res as CoQuanQuanLy[];
        this.dataSource = new MatTableDataSource(this.CoQuanQuanLyService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetAllToListAsync();
    }
  }
  onSave(element: CoQuanQuanLy) {    
    this.CoQuanQuanLyService.SaveAsync(element).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: CoQuanQuanLy) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoQuanQuanLyService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
}