import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';
import { DanhMucTinhThanh } from 'src/app/shared/DanhMucTinhThanh.model';
import { DanhMucTinhThanhService } from 'src/app/shared/DanhMucTinhThanh.service';
import { DownloadService } from 'src/app/shared/Download.service';

@Component({
  selector: 'app-danh-muc-quan-huyen',
  templateUrl: './danh-muc-quan-huyen.component.html',
  styleUrls: ['./danh-muc-quan-huyen.component.css']
})
export class DanhMucQuanHuyenComponent implements OnInit {

  dataSource: MatTableDataSource<any>;  
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;

  constructor(
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DanhMucTinhThanhService: DanhMucTinhThanhService,
    public NotificationService: NotificationService,
    public DownloadService: DownloadService, 
  ) { }

  ngOnInit(): void {
    this.DanhMucTinhThanhGetAllToListAsync();
    this.GetAllAndEmptyToListAsync();
  }

  DanhMucTinhThanhGetAllToListAsync() {
    this.isShowLoading = true;
    this.DanhMucTinhThanhService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucTinhThanhService.list = res as DanhMucTinhThanh[];
        this.DanhMucTinhThanhService.list = this.DanhMucTinhThanhService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetAllAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.DanhMucQuanHuyenService.GetByParentIDAndEmptyToListAsync(this.parentID).subscribe(
      res => {
        this.DanhMucQuanHuyenService.list = res as DanhMucQuanHuyen[];
        this.dataSource = new MatTableDataSource(this.DanhMucQuanHuyenService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetAllAndEmptyToListAsync();
    }
  }
  onSave(element: DanhMucQuanHuyen) {
    this.DanhMucQuanHuyenService.SaveAsync(element).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: DanhMucQuanHuyen) {
    if (confirm(environment.DeleteConfirm)) {
      this.DanhMucQuanHuyenService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
  onDownloadExcel() {
    this.isShowLoading = true;
    this.DownloadService.DanhMucQuanHuyenToExcelAsync(this.parentID).subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
}

