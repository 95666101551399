import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { CoSoLuuTruPhong } from 'src/app/shared/CoSoLuuTruPhong.model';
import { CoSoLuuTruPhongService } from 'src/app/shared/CoSoLuuTruPhong.service';
import { CoSoLuuTru } from 'src/app/shared/CoSoLuuTru.model';
import { CoSoLuuTruService } from 'src/app/shared/CoSoLuuTru.service';

@Component({
  selector: 'app-phong',
  templateUrl: './phong.component.html',
  styleUrls: ['./phong.component.css']
})
export class PhongComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;

  constructor(
    public CoSoLuuTruPhongService: CoSoLuuTruPhongService,
    public CoSoLuuTruService: CoSoLuuTruService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.CoSoLuuTruGetAllToListAsync();
    this.GetByParentIDAndEmptyToListAsync();
  }

  CoSoLuuTruGetAllToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruService.GetAllToListAsync().subscribe(
      res => {
        this.CoSoLuuTruService.list = res as CoSoLuuTru[];
        this.CoSoLuuTruService.list= this.CoSoLuuTruService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }


  GetByParentIDAndEmptyToListAsync() {
    this.isShowLoading = true;
    this.CoSoLuuTruPhongService.GetByParentIDAndEmptyToListAsync(this.parentID).subscribe(
      res => {
        this.CoSoLuuTruPhongService.list = res as CoSoLuuTruPhong[];
        this.dataSource = new MatTableDataSource(this.CoSoLuuTruPhongService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetByParentIDAndEmptyToListAsync();
    }
  }
  onSave(element: CoSoLuuTruPhong) {
    element.ParentID=this.parentID;
    this.CoSoLuuTruPhongService.SaveAsync(element).subscribe(
      res => {
        this.onSearch();
        this.NotificationService.warn(environment.SaveSuccess);
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onDelete(element: CoSoLuuTruPhong) {
    if (confirm(environment.DeleteConfirm)) {
      this.CoSoLuuTruPhongService.RemoveAsync(element.ID).subscribe(
        res => {
          this.onSearch();
          this.NotificationService.warn(environment.DeleteSuccess);
        },
        err => {
          this.NotificationService.warn(environment.DeleteNotSuccess);
        }
      );
    }
  }
}