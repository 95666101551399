import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoSoLuuTruDiaDiem } from 'src/app/shared/CoSoLuuTruDiaDiem.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class CoSoLuuTruDiaDiemService extends BaseService{
    displayColumns: string[] = ['Name', 'Note', 'Active', 'Save']; 
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "CoSoLuuTruDiaDiem";
    }
}

