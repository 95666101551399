import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material/material.module';
import { CKEditorModule } from 'ngx-ckeditor';
import { NotificationService } from './shared/Notification.service';
import { CookieService } from 'ngx-cookie-service';
import { ChartsModule } from 'ng2-charts';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { AppComponent } from './app.component';
import { LoadingComponent } from './loading/loading.component';
import { HomepageComponent } from './homepage/homepage.component';
import { CoSoLuuTruComponent } from './co-so-luu-tru/co-so-luu-tru.component';
import { UploadComponent } from './upload/upload.component';
import { DanhMucTinhThanhComponent } from './danh-muc-tinh-thanh/danh-muc-tinh-thanh.component';
import { DanhMucQuanHuyenComponent } from './danh-muc-quan-huyen/danh-muc-quan-huyen.component';
import { DanhMucXaPhuongComponent } from './danh-muc-xa-phuong/danh-muc-xa-phuong.component';
import { DanhMucQuocGiaComponent } from './danh-muc-quoc-gia/danh-muc-quoc-gia.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DanhMucCoSoLuuTruComponent } from './danh-muc-co-so-luu-tru/danh-muc-co-so-luu-tru.component';
import { CoSoLuuTruInfoComponent } from './co-so-luu-tru/co-so-luu-tru-info/co-so-luu-tru-info.component';
import { CoSoLuuTruHoatDongComponent } from './co-so-luu-tru-hoat-dong/co-so-luu-tru-hoat-dong.component';
import { CoSoLuuTruHoatDongDetailComponent } from './co-so-luu-tru-hoat-dong/co-so-luu-tru-hoat-dong-detail/co-so-luu-tru-hoat-dong-detail.component';
import { CoSoLuuTruSuKienComponent } from './co-so-luu-tru-su-kien/co-so-luu-tru-su-kien.component';
import { CoSoLuuTruSuKienDetailComponent } from './co-so-luu-tru-su-kien/co-so-luu-tru-su-kien-detail/co-so-luu-tru-su-kien-detail.component';
import { CoSoLuuTruTourComponent } from './co-so-luu-tru-tour/co-so-luu-tru-tour.component';
import { CoSoLuuTruTourDetailComponent } from './co-so-luu-tru-tour/co-so-luu-tru-tour-detail/co-so-luu-tru-tour-detail.component';
import { HinhAnhComponent } from './hinh-anh/hinh-anh.component';
import { VideoComponent } from './video/video.component';
import { FileComponent } from './file/file.component';
import { PhongComponent } from './phong/phong.component';
import { DiaDiemComponent } from './dia-diem/dia-diem.component';
import { NhaHangComponent } from './nha-hang/nha-hang.component';
import { MonAnComponent } from './mon-an/mon-an.component';
import { DichVuComponent } from './dich-vu/dich-vu.component';
import { HoTroComponent } from './ho-tro/ho-tro.component';
import { SocialComponent } from './social/social.component';
import { HoatDongComponent } from './hoat-dong/hoat-dong.component';
import { SuKienComponent } from './su-kien/su-kien.component';
import { TourComponent } from './tour/tour.component';
import { DiaDiemDaChieuComponent } from './dia-diem-da-chieu/dia-diem-da-chieu.component';
import { CoQuanQuanLyComponent } from './co-quan-quan-ly/co-quan-quan-ly.component';
import { CoQuanQuanLyInfoComponent } from './co-quan-quan-ly/co-quan-quan-ly-info/co-quan-quan-ly-info.component';
import { DanhMucThanhVienComponent } from './danh-muc-thanh-vien/danh-muc-thanh-vien.component';
import { ThanhVienComponent } from './thanh-vien/thanh-vien.component';
import { ThanhVienInfoComponent } from './thanh-vien/thanh-vien-info/thanh-vien-info.component';
import { DanhMucChucNangComponent } from './danh-muc-chuc-nang/danh-muc-chuc-nang.component';
import { DanhMucUngDungComponent } from './danh-muc-ung-dung/danh-muc-ung-dung.component';
import { ThanhVienPhanQuyenUngDungComponent } from './thanh-vien-phan-quyen-ung-dung/thanh-vien-phan-quyen-ung-dung.component';
import { ThanhVienPhanQuyenChucNangComponent } from './thanh-vien-phan-quyen-chuc-nang/thanh-vien-phan-quyen-chuc-nang.component';
import { ThanhVienPhanQuyenKhuVucComponent } from './thanh-vien-phan-quyen-khu-vuc/thanh-vien-phan-quyen-khu-vuc.component';
import { ThanhVienTokenComponent } from './thanh-vien-token/thanh-vien-token.component';
import { ThanhVienLichSuTruyCapComponent } from './thanh-vien-lich-su-truy-cap/thanh-vien-lich-su-truy-cap.component';
import { ThanhVienThongTinComponent } from './thanh-vien-thong-tin/thanh-vien-thong-tin.component';
import { ThanhVienQuenMatKhauComponent } from './thanh-vien-quen-mat-khau/thanh-vien-quen-mat-khau.component';
import { ThanhVienCoQuanQuanLyComponent } from './thanh-vien/thanh-vien-co-quan-quan-ly/thanh-vien-co-quan-quan-ly.component';
import { ThanhVienCoSoLuuTruComponent } from './thanh-vien/thanh-vien-co-so-luu-tru/thanh-vien-co-so-luu-tru.component';
import { ThanhVienDuKhachComponent } from './thanh-vien/thanh-vien-du-khach/thanh-vien-du-khach.component';

import { ChuongTrinhQuangBaXucTienComponent } from './chuong-trinh-quang-ba-xuc-tien/chuong-trinh-quang-ba-xuc-tien.component';
import { ChuongTrinhQuangBaXucTienDetailComponent } from './chuong-trinh-quang-ba-xuc-tien/chuong-trinh-quang-ba-xuc-tien-detail/chuong-trinh-quang-ba-xuc-tien-detail.component';
import { CoSoLuuTruHomeStayComponent } from './co-so-luu-tru/co-so-luu-tru-homestay/co-so-luu-tru-homestay.component';
import { CoSoLuuTruCoSoLuuTruComponent } from './co-so-luu-tru/co-so-luu-tru-co-so-luu-tru/co-so-luu-tru-co-so-luu-tru.component';
import { CoSoLuuTruDiemDuLichComponent } from './co-so-luu-tru/co-so-luu-tru-diem-du-lich/co-so-luu-tru-diem-du-lich.component';
import { CoSoLuuTruNhaHangComponent } from './co-so-luu-tru/co-so-luu-tru-nha-hang/co-so-luu-tru-nha-hang.component';
import { CoSoLuuTruKhachSanComponent } from './co-so-luu-tru/co-so-luu-tru-khach-san/co-so-luu-tru-khach-san.component';
import { CoSoLuuTruDonViLuHanhComponent } from './co-so-luu-tru/co-so-luu-tru-don-vi-lu-hanh/co-so-luu-tru-don-vi-lu-hanh.component';
import { DanhMucPheDuyetComponent } from './danh-muc-phe-duyet/danh-muc-phe-duyet.component';
import { ThanhVienThongBaoComponent } from './thanh-vien-thong-bao/thanh-vien-thong-bao.component';
import { DanhMucVanBanComponent } from './danh-muc-van-ban/danh-muc-van-ban.component';
import { CoQuanQuanLyFileComponent } from './co-quan-quan-ly-file/co-quan-quan-ly-file.component';
import { CoQuanQuanLyFileDetailComponent } from './co-quan-quan-ly-file/co-quan-quan-ly-file-detail/co-quan-quan-ly-file-detail.component';
import { DatPhongTrucTuyenComponent } from './dat-phong-truc-tuyen/dat-phong-truc-tuyen.component';
import { DatPhongTrucTuyenDetailComponent } from './dat-phong-truc-tuyen/dat-phong-truc-tuyen-detail/dat-phong-truc-tuyen-detail.component';


@NgModule({
  declarations: [
    AppComponent,
    LoadingComponent,
    HomepageComponent,
    CoSoLuuTruComponent,
    UploadComponent,
    DanhMucTinhThanhComponent,
    DanhMucQuanHuyenComponent,
    DanhMucXaPhuongComponent,
    DanhMucQuocGiaComponent,
    DanhMucCoSoLuuTruComponent,
    DashboardComponent,
    CoSoLuuTruInfoComponent,
    CoSoLuuTruHoatDongComponent,
    CoSoLuuTruHoatDongDetailComponent,
    CoSoLuuTruSuKienComponent,
    CoSoLuuTruSuKienDetailComponent,
    CoSoLuuTruTourComponent,
    CoSoLuuTruTourDetailComponent,
    HinhAnhComponent,
    VideoComponent,
    FileComponent,
    PhongComponent,
    DiaDiemComponent,
    NhaHangComponent,
    MonAnComponent,
    DichVuComponent,
    HoTroComponent,
    SocialComponent,
    HoatDongComponent,
    SuKienComponent,
    TourComponent,
    DiaDiemDaChieuComponent,
    CoQuanQuanLyComponent,
    CoQuanQuanLyInfoComponent,
    DanhMucThanhVienComponent,
    ThanhVienComponent,
    ThanhVienInfoComponent,
    DanhMucChucNangComponent,
    DanhMucUngDungComponent,
    ThanhVienPhanQuyenUngDungComponent,
    ThanhVienPhanQuyenChucNangComponent,
    ThanhVienPhanQuyenKhuVucComponent,
    ThanhVienTokenComponent,
    ThanhVienLichSuTruyCapComponent,
    ThanhVienThongTinComponent,
    ThanhVienQuenMatKhauComponent,
    ChuongTrinhQuangBaXucTienComponent,
    ChuongTrinhQuangBaXucTienDetailComponent,
    CoSoLuuTruHomeStayComponent,
    CoSoLuuTruCoSoLuuTruComponent,
    CoSoLuuTruDiemDuLichComponent,
    CoSoLuuTruNhaHangComponent,
    CoSoLuuTruKhachSanComponent,
    CoSoLuuTruDonViLuHanhComponent,
    DanhMucPheDuyetComponent,
    ThanhVienCoQuanQuanLyComponent,
    ThanhVienCoSoLuuTruComponent,
    ThanhVienDuKhachComponent,
    ThanhVienThongBaoComponent,
    DanhMucVanBanComponent,
    CoQuanQuanLyFileComponent,
    CoQuanQuanLyFileDetailComponent,
    DatPhongTrucTuyenComponent,
    DatPhongTrucTuyenDetailComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    FormsModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ChartsModule,
    CKEditorModule,
  ],
  providers: [   
    CookieService,  
    NotificationService,
    {provide: MAT_DATE_LOCALE, useValue: 'en-GB'}
  ],
  bootstrap: [AppComponent],
  entryComponents:[]
})
export class AppModule { }
