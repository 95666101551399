import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ChuongTrinhQuangBaXucTien } from 'src/app/shared/ChuongTrinhQuangBaXucTien.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ChuongTrinhQuangBaXucTienService extends BaseService{
    displayColumns: string[] = ['Name', 'NgayBatDau', 'NgayKetThuc', 'Active', 'Save'];
    list: ChuongTrinhQuangBaXucTien[] | undefined;
    listSearch: ChuongTrinhQuangBaXucTien[] | undefined;
    listSearch001: ChuongTrinhQuangBaXucTien[] | undefined;
    formData!: ChuongTrinhQuangBaXucTien;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ChuongTrinhQuangBaXucTien";
    }
}

