<div class="row" style="padding-bottom: 50px;">
    <div id="breadcrumbs-wrapper" data-image="/assets/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Upload Danh mục</h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">CMS</a>
                        </li>
                        <li class="breadcrumb-item">
                            <a href="#" style="color: #ffffff;">Upload</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">Danh mục
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s6">
        <div class="container">
            <div class="section section-data-tables">
                <div class="card">
                    <div class="card-content">
                        <div class="col s12 m12 l12">
                            <h5>Upload Cơ sở lưu trú</h5>
                            <div class="input-field col s12">
                                Bước 01: <a target="_blank" href="{{excelCoSoLuuTruURL}}">Tải về</a> file
                                excel mẫu
                            </div>
                            <div class="input-field col s12">
                                Bước 02: Chọn file excel có dữ liệu:
                                <input type="file" #uploadCoSoLuuTru (change)="changeCoSoLuuTru($event.target.files)">
                            </div>
                            <div class="input-field col s12">
                                Bước 03: Chọn loại lưu trú:
                                <mat-select
                                    style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                    name="ParentID" class="browser-default"
                                    [(ngModel)]="this.danhMucCoSoLuuTruID"
                                    (selectionChange)="changeSelectDanhMucCoSoLuuTru()"
                                    >
                                    <mat-option [value]="0">Chọn cơ sở</mat-option>
                                    <mat-option *ngFor="let item of DanhMucCoSoLuuTruService.list" [value]="item.ID">
                                        {{item.Name}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="input-field col s12">
                                <button [disabled]="!isCoSoLuuTru" class="btn btn-primary"
                                    (click)="onSubmitCoSoLuuTru()">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col s6">
        <div class="container">
            <div class="section section-data-tables">
                <div class="card">
                    <div class="card-content">
                        <div class="col s12 m12 l12">
                            <h5>Upload Quốc Gia</h5>
                            <div class="input-field col s12">
                                Bước 01: <a target="_blank" href="{{excelDanhMucQuocGiaURL}}">Tải về</a> file
                                excel mẫu
                            </div>
                            <div class="input-field col s12">
                                Bước 02: Chọn file excel có dữ liệu:
                                <input type="file" #uploadDanhMucQuocGia
                                    (change)="changeDanhMucQuocGia($event.target.files)">
                            </div>
                            <div class="input-field col s12">
                                <button [disabled]="!isDanhMucQuocGia" class="btn btn-primary"
                                    (click)="onSubmitDanhMucQuocGia()">Upload</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>