<div class="row">
    <div id="breadcrumbs-wrapper" data-image="/assets/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Online booking <span
                            *ngIf="DatPhongTrucTuyenService.list">({{DatPhongTrucTuyenService.list.length }}
                            items)</span></h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">Tourists</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">Online booking
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section section-data-tables">
                <div class="card">
                    <div class="card-content">                        
                        <div class="col s9 m9 l9">
                            <input matInput [(ngModel)]="searchString" placeholder="Search ..."
                                class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                (keyup.enter)="onSearch()">
                        </div>
                        <div class="col s9 m9 l3">
                            <a title="Search" (click)="onSearch()"
                                class="btn-floating waves-effect waves-light green darken-1"><i
                                    class="material-icons">search</i></a>&nbsp;&nbsp;&nbsp;
                            <a title="Add" (click)="onAdd(0)"
                                class="btn-floating waves-effect waves-light green darken-1"><i
                                    class="material-icons">add</i></a>
                        </div>
                        <div class="KhungOverflow">
                            <mat-table [dataSource]="dataSource" matSort>                                
                                <ng-container matColumnDef="CoSoLuuTruID">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Unit
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <div *ngFor="let item of CoSoLuuTruService.list;">
                                            <div *ngIf="item.ID==element.CoSoLuuTruID">
                                                {{item.TenDangKyKinhDoanh}}
                                            </div>
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="CoSoLuuTruPhongID">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Category
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <div *ngFor="let item of CoSoLuuTruPhongService.list;">
                                            <div *ngIf="item.ID==element.CoSoLuuTruPhongID">
                                                {{item.Name}}
                                            </div>
                                        </div>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="SoNguoi">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        People
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        {{element.SoNguoi | number: '1.0-0'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="SoPhong">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Room
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        {{element.SoPhong | number: '1.0-0'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="BatDau">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Check in
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        {{element.BatDau |
                                        date:'yyyy-MM-dd'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="KetThuc">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Check out
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        {{element.KetThuc |
                                        date:'yyyy-MM-dd'}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Note">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Note
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        {{element.Note}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                        Active
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        <label>
                                            <input type="checkbox" class="filled-in" name="Active{{element.ID}}"
                                                [(ngModel)]="element.Active" />
                                            <span>.</span>
                                        </label>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <a style="padding-left: 8px;" (click)="onDelete(element)" *ngIf="element.ID>0"
                                            class="btn-floating waves-effect waves-light red"><i
                                                class="material-icons">delete_outline</i></a>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="loading">
                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                        Loading...
                                    </mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="noData">
                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                        No data yet.
                                    </mat-footer-cell>
                                </ng-container>
                                <mat-header-row
                                    *matHeaderRowDef="DatPhongTrucTuyenService.displayColumns"></mat-header-row>
                                <mat-row
                                    *matRowDef="let row; columns: DatPhongTrucTuyenService.displayColumns;"></mat-row>
                                <mat-footer-row *matFooterRowDef="['loading']"
                                    [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                                <mat-footer-row *matFooterRowDef="['noData']"
                                    [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}">
                                </mat-footer-row>
                            </mat-table>
                            <!-- <mat-paginator [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                [showFirstLastButtons]></mat-paginator> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>