import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { DanhMucThanhVien } from 'src/app/shared/DanhMucThanhVien.model';
import { DanhMucThanhVienService } from 'src/app/shared/DanhMucThanhVien.service';
import { DownloadService } from 'src/app/shared/Download.service';

@Component({
  selector: 'app-thanh-vien-du-khach',
  templateUrl: './thanh-vien-du-khach.component.html',
  styleUrls: ['./thanh-vien-du-khach.component.css']
})
export class ThanhVienDuKhachComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  URLSub: string = environment.DomainDestination + "ThanhVienInfo";
  danhMucThanhVienID: number = environment.InitializationNumber;
  constructor(
    public ThanhVienService: ThanhVienService,
    public DanhMucThanhVienService: DanhMucThanhVienService,
    public DownloadService: DownloadService,
    public NotificationService: NotificationService,
    public dialog: MatDialog
  ) { }

  ngOnInit(): void {
    this.GetAllToListAsync();
    this.DanhMucThanhVienGetToListAsync();
  }
  DanhMucThanhVienGetToListAsync() {
    this.DanhMucThanhVienService.GetAllToListAsync().subscribe(
      res => {
        this.DanhMucThanhVienService.list = (res as DanhMucThanhVien[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
      },
      err => {
      }
    );
  }
  GetAllToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienService.GetBySearchStringOrDanhMucThanhVienIDToListAsync(this.searchString, environment.ThanhVienDuKhachID).subscribe(
      res => {
        this.ThanhVienService.list = res as ThanhVien[];
        this.dataSource = new MatTableDataSource(this.ThanhVienService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    this.GetAllToListAsync();
  }
  onDownloadExcel() {
    this.isShowLoading = true;
    this.DownloadService.ReportThanhVienByDanhMucThanhVienIDToExcelAsync(environment.ThanhVienDuKhachID).subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
  onPrint() {
    this.isShowLoading = true;
    this.DownloadService.ReportThanhVienByDanhMucThanhVienIDToHTMLAsync(environment.ThanhVienDuKhachID).subscribe(
      res => {
        window.open(res.toString(), "_blank");
        this.isShowLoading = false;
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
        this.isShowLoading = false;
      }
    );
  }
}