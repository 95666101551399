import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoSoLuuTruVideo } from 'src/app/shared/CoSoLuuTruVideo.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class CoSoLuuTruVideoService extends BaseService{
    displayColumns: string[] = ['Name', 'Active', 'Save'];
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "CoSoLuuTruVideo";
    }
    SaveAndUploadVideosAsync(formData: CoSoLuuTruVideo, fileToUpload: FileList) {
        let url = this.aPIURL + this.controller + '/SaveAndUploadVideosAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('data', JSON.stringify(formData));
        if (fileToUpload) {
            if (fileToUpload.length > 0) {
                for (var i = 0; i < fileToUpload.length; i++) {
                    formUpload.append('file[]', fileToUpload[i]);
                }
            }
        }
        return this.httpClient.post(url, formUpload);
    }
}

