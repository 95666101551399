import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PheDuyet } from 'src/app/shared/PheDuyet.model';
import { BaseService } from './Base.service';
import { CoSoLuuTru } from './CoSoLuuTru.model';
@Injectable({
    providedIn: 'root'
})
export class PheDuyetService extends BaseService{
    displayColumns: string[] = ['NguoiYeuCauID', 'CoQuanQuanLyNguoiYeuCauID', 'NoiDungYeuCau', 'NgayYeuCau','NguoiPheDuyetID', 'CoQuanQuanLyNguoiPheDuyetID', 'NoiDungPheDuyet', 'NgayPheDuyet','DanhMucPheDuyetID', 'Save'];
    list: PheDuyet[] | undefined;
    listSearch: PheDuyet[] | undefined;
    listSearch001: PheDuyet[] | undefined;
    formData!: PheDuyet;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "PheDuyet";
    }   
}

