import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienPhanQuyenKhuVuc } from 'src/app/shared/ThanhVienPhanQuyenKhuVuc.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienPhanQuyenKhuVucService extends BaseService {
    displayColumns: string[] = ['DanhMucQuanHuyenID', 'DanhMucXaPhuongID', 'Active'];
    list: ThanhVienPhanQuyenKhuVuc[] | undefined;
    formData!: ThanhVienPhanQuyenKhuVuc;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThanhVienPhanQuyenKhuVuc";
    }
    GetByParentIDAndDanhMucQuanHuyenIDToListAsync(parentID: number, danhMucQuanHuyenID: number) {
        let url = this.aPIURL + this.controller + '/GetByParentIDAndDanhMucQuanHuyenIDToListAsync';
        const formUpload: FormData = new FormData();
        formUpload.append('parentID', JSON.stringify(parentID));
        formUpload.append('danhMucQuanHuyenID', JSON.stringify(danhMucQuanHuyenID));
        return this.httpClient.post(url, formUpload);
    }
}

