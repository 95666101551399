<div class="row">
    <div id="breadcrumbs-wrapper" data-image="https://crm.vnptvungtau.vn/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">
                        Đơn vị
                    </h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">Cơ sở lưu trú</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">
                            Đơn vị
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <div id="basic-tabs" class="card card card-default scrollspy">
                            <div class="card-content">

                                <div id="1">
                                    <form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
                                        <div class="col s6 m6 l12">
                                            <div class="card-title">
                                                <a title="Danh sách" href="javascript:window.open('','_self').close();"
                                                    style="padding-top: 3px;"
                                                    class="btn-floating waves-effect waves-light green darken-1"><i
                                                        class="material-icons">arrow_back</i></a>&nbsp;&nbsp;&nbsp;

                                                <button [disabled]="!CoSoLuuTruService.formData.TenDangKyKinhDoanh"
                                                    title="Lưu thay đổi" type="submit" style="padding-top: 3px;"
                                                    class="btn-floating waves-effect waves-light cyan"><i
                                                        class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
                                            </div>
                                        </div>
                                        <input name="ID" [(ngModel)]="CoSoLuuTruService.formData.ID" type="hidden">
                                        <input name="DanhMucTinhThanhID"
                                            [(ngModel)]="CoSoLuuTruService.formData.DanhMucTinhThanhID" type="hidden">
                                        <div class="col s12 m12 l6">
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="TenDangKyKinhDoanh">Tên</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Tên đăng ký kinh doanh"
                                                        name="TenDangKyKinhDoanh"
                                                        [(ngModel)]="CoSoLuuTruService.formData.TenDangKyKinhDoanh"
                                                        type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">Mã số thuế</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Mã số thuế" name="MaSoThue"
                                                        [(ngModel)]="CoSoLuuTruService.formData.MaSoThue" type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">Mã số</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Mã số đăng ký kinh doanh"
                                                        name="MaSoDangKyKinhDoanh"
                                                        [(ngModel)]="CoSoLuuTruService.formData.MaSoDangKyKinhDoanh"
                                                        type="text">
                                                </div>
                                            </div>

                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">Điện thoại</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Điện thoại" name="DienThoai"
                                                        [(ngModel)]="CoSoLuuTruService.formData.DienThoai" type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">Email</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Email" name="Email"
                                                        [(ngModel)]="CoSoLuuTruService.formData.Email" type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">Trang web</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Website" name="Website"
                                                        [(ngModel)]="CoSoLuuTruService.formData.Website" type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Note">Ghi chú</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Ghi chú" name="Note"
                                                        [(ngModel)]="CoSoLuuTruService.formData.Note" type="text">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col s12 m12 l6">
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Note">Loại cơ sở</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <mat-select
                                                        style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="ParentID" class="browser-default"
                                                        [(ngModel)]="CoSoLuuTruService.formData.ParentID"
                                                        (selectionChange)="DanhMucCoSoLuuTruGetAllToListAsync()">
                                                        <mat-option *ngFor="let item of DanhMucCoSoLuuTruService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>

                                            </div>

                                            <!-- <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Note">Thành Phố</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <mat-select
                                                        style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="tinhThanhID" class="browser-default" [(ngModel)]="tinhThanhID" (selectionChange)="DanhMucQuanHuyenGetAllToListAsync()">
                                                        <mat-option *ngFor="let item of DanhMucTinhThanhService.list" [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                            </div> -->
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Note">Quận Huyện</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <mat-select
                                                        style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucQuanHuyenID" class="browser-default"
                                                        [(ngModel)]="CoSoLuuTruService.formData.DanhMucQuanHuyenID"
                                                        (selectionChange)="DanhMucXaPhuongGetAllToListAsync()">
                                                        <mat-option *ngFor="let item of DanhMucQuanHuyenService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>

                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Note">Xã Phường</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <mat-select
                                                        style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucXaPhuongID" class="browser-default"
                                                        [(ngModel)]="CoSoLuuTruService.formData.DanhMucXaPhuongID">
                                                        <mat-option *ngFor="let item of DanhMucXaPhuongService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Note">Địa chỉ</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <div class="input-field">
                                                        <input placeholder="Số nhà/ tên đường" name="DiaChi"
                                                            [(ngModel)]="CoSoLuuTruService.formData.DiaChi" type="text">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">Người đại diện</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Người đại diện pháp luật"
                                                        name="NguoiDaiDienPhapLuat"
                                                        [(ngModel)]="CoSoLuuTruService.formData.NguoiDaiDienPhapLuat"
                                                        type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">Điện thoại</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Điện thoại người đại diện pháp luật"
                                                        name="NguoiDaiDienPhapLuatDienThoai"
                                                        [(ngModel)]="CoSoLuuTruService.formData.NguoiDaiDienPhapLuatDienThoai"
                                                        type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">CCCD</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="CCCD người đại diện pháp luật"
                                                        name="NguoiDaiDienPhapLuatCCCD"
                                                        [(ngModel)]="CoSoLuuTruService.formData.NguoiDaiDienPhapLuatCCCD"
                                                        type="text">
                                                </div>
                                            </div>


                                            <!-- <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">Facebook</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Facebook" name="Facebook"
                                                        [(ngModel)]="CoSoLuuTruService.formData.Facebook" type="text">
                                                </div>
                                            </div>
                                            <div class="col s4 m4 l4">
                                                <div class="input-field">
                                                    <label for="Name">Tiktok</label>
                                                </div>
                                            </div>
                                            <div class="col s8 m8 l8">
                                                <div class="input-field">
                                                    <input placeholder="Tiktok" name="Tiktok"
                                                        [(ngModel)]="CoSoLuuTruService.formData.Tiktok" type="text">
                                                </div>
                                            </div> -->



                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách hoạt động</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="HoatDongSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="HoatDongSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="HoatDongSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a title="Thêm mới" (click)="HoatDongAdd(0)"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">add</i></a>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="HoatDongDataSource" matSort #HoatDongSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Hoạt động
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <a title="{{element.Name}}"
                                                        (click)="HoatDongAdd(element.ID)"><b>{{element.Name}}</b>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NgayBatDau">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Bắt đầu
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">

                                                    <b>{{element.NgayBatDau | date:'yyyy-MM-dd'}}</b>

                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NgayKetThuc">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Kết thúc
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <b>{{element.NgayKetThuc| date:'yyyy-MM-dd' }}</b>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Kích hoạt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in" readonly
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="HoatDongDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruHoatDongService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruHoatDongService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':HoatDongDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(HoatDongDataSource!=null && HoatDongDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #HoatDongPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách sự kiện</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="SuKienSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="SuKienSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="SuKienSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a title="Thêm mới" (click)="SuKienAdd(0)"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">add</i></a>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="SuKienDataSource" matSort #SuKienSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Sự kiện
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <a title="{{element.Name}}"
                                                        (click)="HoatDongAdd(element.ID)"><b>{{element.Name}}</b>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NgayBatDau">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Bắt đầu
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <b>{{element.NgayBatDau| date:'yyyy-MM-dd' }}</b>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NgayKetThuc">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Kết thúc
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <b>{{element.NgayKetThuc| date:'yyyy-MM-dd' }}</b>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Kích hoạt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in" readonly
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="SuKienDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruSuKienService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruSuKienService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':SuKienDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(SuKienDataSource!=null && SuKienDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #SuKienPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách tour</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="TourSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="TourSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="TourSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                        <a title="Thêm mới" (click)="TourAdd(0)"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">add</i></a>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="TourDataSource" matSort #TourSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Tour
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <a title="{{element.Name}}"
                                                        (click)="TourAdd(element.ID)"><b>{{element.Name}}</b>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NgayBatDau">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Bắt đầu
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <b>{{element.NgayBatDau| date:'yyyy-MM-dd' }}</b>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NgayKetThuc">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Kết thúc
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <b>{{element.NgayKetThuc| date:'yyyy-MM-dd' }}</b>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="GiaCaoNhat">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Giá cao nhất
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <b>{{element.GiaCaoNhat | number:
                                                        '1.0-0' }}</b>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="GiaThapNhat">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Giá thấp nhất
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <b>{{element.GiaThapNhat | number:
                                                        '1.0-0'}}</b>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Kích hoạt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in" readonly
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="TourDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruTourService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruTourService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':TourDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(TourDataSource!=null && TourDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #TourPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                            [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách phòng</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="PhongSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="PhongSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="PhongSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="PhongDataSource" matSort #PhongSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Phòng
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Tên phòng" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="GiaThapNhat">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Giá cao nhất
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Giá cao nhất" name="GiaThapNhat{{element.ID}}"
                                                        [(ngModel)]="element.GiaThapNhat" type="number">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="GiaCaoNhat">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Giá thấp nhất
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Giá thấp nhất" name="GiaCaoNhat{{element.ID}}"
                                                        [(ngModel)]="element.GiaCaoNhat" type="number">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Note">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Ghi chú" name="Note{{element.ID}}"
                                                        [(ngModel)]="element.ID" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Kích hoạt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a title="Lưu thay đổi" (click)="PhongSave(element)"
                                                        class="btn-floating waves-effect waves-light cyan">
                                                        <i class="material-icons">save</i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a style="padding-left: 8px;" (click)="PhongDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruPhongService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruPhongService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':PhongDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(PhongDataSource!=null && PhongDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #PhongPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                            [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách địa điểm</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="DiaDiemSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="DiaDiemSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="DiaDiemSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="DiaDiemDataSource" matSort #DiaDiemSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Địa điểm
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Tên địa điểm" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" type="text">
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="Note">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Ghi chú" name="Note{{element.ID}}"
                                                        [(ngModel)]="element.Note" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Kích hoạt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a title="Lưu thay đổi" (click)="DiaDiemSave(element)"
                                                        class="btn-floating waves-effect waves-light cyan">
                                                        <i class="material-icons">save</i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a style="padding-left: 8px;" (click)="DiaDiemDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruDiaDiemService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruDiaDiemService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':DiaDiemDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(DiaDiemDataSource!=null && DiaDiemDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #DiaDiemPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách nhà hàng</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="NhaHangSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="NhaHangSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="NhaHangSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="NhaHangDataSource" matSort #NhaHangSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Nhà hàng
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Tên nhà hàng" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" type="text">
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="Note">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Ghi chú" name="Note{{element.ID}}"
                                                        [(ngModel)]="element.Note" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Kích hoạt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a title="Lưu thay đổi" (click)="NhaHangSave(element)"
                                                        class="btn-floating waves-effect waves-light cyan">
                                                        <i class="material-icons">save</i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a style="padding-left: 8px;" (click)="NhaHangDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruNhaHangService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruNhaHangService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':NhaHangDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(NhaHangDataSource!=null && NhaHangDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #NhaHangPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách món ăn</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="MonAnSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="MonAnSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="MonAnSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="MonAnDataSource" matSort #MonAnSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Món ăn
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Tên món ăn" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" type="text">
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="Note">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Ghi chú" name="Note{{element.ID}}"
                                                        [(ngModel)]="element.Note" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Kích hoạt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a title="Lưu thay đổi" (click)="MonAnSave(element)"
                                                        class="btn-floating waves-effect waves-light cyan">
                                                        <i class="material-icons">save</i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a style="padding-left: 8px;" (click)="MonAnDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruMonAnService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruMonAnService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':MonAnDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(MonAnDataSource!=null && MonAnDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #MonAnPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                            [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách dịch vụ</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="DichVuSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="DichVuSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="DichVuSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="DichVuDataSource" matSort #DichVuSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Dịch vụ
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Tên dịch vụ" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" type="text">
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="Note">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Ghi chú" name="Note{{element.ID}}"
                                                        [(ngModel)]="element.Note" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Kích hoạt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a title="Lưu thay đổi" (click)="DichVuSave(element)"
                                                        class="btn-floating waves-effect waves-light cyan">
                                                        <i class="material-icons">save</i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a style="padding-left: 8px;" (click)="DichVuDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruDichVuService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruDichVuService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':DichVuDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(DichVuDataSource!=null && DichVuDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #DichVuPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách hỗ trợ</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="HoTroSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="HoTroSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="HoTroSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="HoTroDataSource" matSort #HoTroSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Hỗ trợ
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Tên hỗ trợ" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" type="text">
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="Note">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Ghi chú" name="Note{{element.ID}}"
                                                        [(ngModel)]="element.Note" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Kích hoạt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a title="Lưu thay đổi" (click)="HoTroSave(element)"
                                                        class="btn-floating waves-effect waves-light cyan">
                                                        <i class="material-icons">save</i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a style="padding-left: 8px;" (click)="HoTroDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruHoTroService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruHoTroService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':HoTroDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(HoTroDataSource!=null && HoTroDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #HoTroPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                            [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách mạng xã hội</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="SocialSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="SocialSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="SocialSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="SocialDataSource" matSort #SocialSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Mạng xã hội
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Tên mạng xã hội" name="Name{{element.ID}}"
                                                        [(ngModel)]="element.Name" type="text">
                                                </mat-cell>
                                            </ng-container>

                                            <ng-container matColumnDef="Note">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ghi chú
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Ghi chú" name="Note{{element.ID}}"
                                                        [(ngModel)]="element.Note" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Kích hoạt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a title="Lưu thay đổi" (click)="SocialSave(element)"
                                                        class="btn-floating waves-effect waves-light cyan">
                                                        <i class="material-icons">save</i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a style="padding-left: 8px;" (click)="SocialDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruSocialService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruSocialService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':SocialDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(SocialDataSource!=null && SocialDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #SocialPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách hình ảnh</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s4 m4 l4">
                                        <div class="input-field">
                                            <input type="file" accept="image/*" multiple
                                                (change)="HinhAnhChangeFiles($event.target.files)">
                                        </div>
                                    </div>
                                    <div class="col s4 m4 l4">
                                        <div class="input-field">
                                            <button class="btn btn-primary" [disabled]="!isCoSoLuuTruHinhAnh"
                                                (click)="HinhAnhSubmitFiles()">Upload</button>
                                        </div>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="HinhAnhDataSource" matSort #HinhAnhSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Hình ảnh
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a target="_blank" href="{{element.Name}}" *ngIf="element.Name">
                                                        <img class="responsive-img" src="{{element.Name}}" />
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Kích hoạt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="HinhAnhDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruHinhAnhService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruHinhAnhService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':HinhAnhDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(HinhAnhDataSource!=null && HinhAnhDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #HinhAnhPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách video</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s4 m4 l4">
                                        <div class="input-field">
                                            <input type="file" accept="video/mp4,video/x-m4v,video/*" multiple
                                                (change)="VideoChangeFiles($event.target.files)">
                                        </div>
                                    </div>
                                    <div class="col s4 m4 l4">
                                        <div class="input-field">
                                            <button class="btn btn-primary" [disabled]="!isCoSoLuuTruVideo"
                                                (click)="VideoSubmitFiles()">Upload</button>
                                        </div>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="VideoDataSource" matSort #VideoSort="matSort">
                                            <ng-container matColumnDef="Name">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Video
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a target="_blank" href="{{element.Name}}" *ngIf="element.Name">
                                                        <video class="responsive-img"
                                                            style="max-height: 100px;object-fit: contain;"
                                                            reload="metadata">
                                                            <source src="{{element.Name}}" type="video/mp4">
                                                        </video>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Kích hoạt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="VideoDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruVideoService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruVideoService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':VideoDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(VideoDataSource!=null && VideoDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #VideoPaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                            [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách file</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s4 m4 l4">
                                        <div class="input-field">
                                            <input type="file" accept="application/pdf, application/vnd.ms-excel"
                                                multiple (change)="FileChangeFiles($event.target.files)">
                                        </div>
                                    </div>
                                    <div class="col s4 m4 l4">
                                        <div class="input-field">
                                            <button class="btn btn-primary" [disabled]="!isCoSoLuuTruFile"
                                                (click)="FileSubmitFiles()">Upload</button>
                                        </div>
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="FileDataSource" matSort #FileSort="matSort">
                                            <ng-container matColumnDef="Code">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>File
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a target="_blank" href="{{element.Code}}" *ngIf="element.Code">
                                                        {{element.Code}}
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Active">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                    Kích hoạt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element">
                                                    <label>
                                                        <input type="checkbox" class="filled-in"
                                                            name="Active{{element.ID}}" [(ngModel)]="element.Active" />
                                                        <span>.</span>
                                                    </label>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a style="padding-left: 8px;" (click)="FileDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="CoSoLuuTruFileService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: CoSoLuuTruFileService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':FileDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(FileDataSource!=null && FileDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #FilePaginator="matPaginator" [pageSizeOptions]="[10,20,50,100]"
                                            [pageSize]="10" [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section" *ngIf="this.CoSoLuuTruService.formData.ID>0">
                <div class="row">
                    <div class="col s12 m12 l12">
                        <section>
                            <h3>Danh sách phê duyệt</h3>
                        </section>
                        <div class="section section-data-tables">
                            <div class="card">
                                <div class="card-content">
                                    <div class="col s12 m12 l9">
                                        <input matInput [(ngModel)]="PheDuyetSearchString" placeholder="Tìm ..."
                                            class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                            (keyup.enter)="PheDuyetSearch()">
                                    </div>
                                    <div class="col s12 m12 l3">
                                        <a title="Tìm" (click)="PheDuyetSearch()"
                                            class="btn-floating waves-effect waves-light green darken-1">
                                            <i class="material-icons">search</i>
                                        </a>&nbsp;&nbsp;&nbsp;
                                    </div>
                                    <div class="KhungOverflow">
                                        <mat-table [dataSource]="PheDuyetDataSource" matSort #PheDuyetSort="matSort">
                                            <ng-container matColumnDef="NguoiYeuCauID">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Người yêu cầu
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <mat-select
                                                        style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="NguoiYeuCauID{{element.ID}}"
                                                        class="browser-default"
                                                        [(ngModel)]="element.NguoiYeuCauID">
                                                        <mat-option *ngFor="let item of ThanhVienService.list"
                                                            [value]="item.ID">
                                                            {{item.HoTen}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="CoQuanQuanLyNguoiYeuCauID">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <mat-select
                                                        style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="CoQuanQuanLyNguoiYeuCauID{{element.ID}}"
                                                        class="browser-default"
                                                        [(ngModel)]="element.CoQuanQuanLyNguoiYeuCauID">
                                                        <mat-option *ngFor="let item of CoQuanQuanLyService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NoiDungYeuCau">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Nội dung
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Nội dung yêu cầu"
                                                        name="NoiDungYeuCau{{element.ID}}"
                                                        [(ngModel)]="element.NoiDungYeuCau" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NgayYeuCau">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ngày yêu cầu
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Ngày yêu cầu" name="NgayYeuCau{{element.ID}}"
                                                        [(ngModel)]="element.NgayYeuCau"
                                                         type="date">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NguoiPheDuyetID">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Người phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <mat-select
                                                        style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="NguoiPheDuyetID{{element.ID}}"
                                                        class="browser-default"
                                                        [(ngModel)]="element.NguoiPheDuyetID">
                                                        <mat-option *ngFor="let item of ThanhVienService.list"
                                                            [value]="item.ID">
                                                            {{item.HoTen}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="CoQuanQuanLyNguoiPheDuyetID">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Đơn vị
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <mat-select
                                                        style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="CoQuanQuanLyNguoiPheDuyetID{{element.ID}}"
                                                        class="browser-default"
                                                        [(ngModel)]="element.CoQuanQuanLyNguoiPheDuyetID">
                                                        <mat-option *ngFor="let item of CoQuanQuanLyService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NoiDungPheDuyet">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Nội dung
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Nội dung phê duyệt"
                                                        name="NoiDungPheDuyet{{element.ID}}"
                                                        [(ngModel)]="element.NoiDungPheDuyet" type="text">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="NgayPheDuyet">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Ngày phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <input placeholder="Ngày phê duyệt"
                                                        name="NgayPheDuyet{{element.ID}}"
                                                        [(ngModel)]="element.NgayPheDuyet" type="date">
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="DanhMucPheDuyetID">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>Loại phê duyệt
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <mat-select
                                                        style="padding-top:10px; padding-bottom:6px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                                        name="DanhMucPheDuyetID{{element.ID}}"
                                                        class="browser-default"
                                                        [(ngModel)]="element.DanhMucPheDuyetID">
                                                        <mat-option *ngFor="let item of DanhMucPheDuyetService.list"
                                                            [value]="item.ID">
                                                            {{item.Name}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="Save">
                                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                                </mat-header-cell>
                                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                                    <a title="Lưu thay đổi" (click)="PheDuyetSave(element)"
                                                        class="btn-floating waves-effect waves-light cyan">
                                                        <i class="material-icons">save</i>
                                                    </a>&nbsp;&nbsp;&nbsp;
                                                    <a style="padding-left: 8px;" (click)="PheDuyetDelete(element)"
                                                        *ngIf="element.ID>0"
                                                        class="btn-floating waves-effect waves-light red">
                                                        <i class="material-icons">delete_outline</i>
                                                    </a>
                                                </mat-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="loading">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Đang tải dữ liệu...
                                                </mat-footer-cell>
                                            </ng-container>
                                            <ng-container matColumnDef="noData">
                                                <mat-footer-cell *matFooterCellDef colspan="4">
                                                    Chưa có dữ liệu.
                                                </mat-footer-cell>
                                            </ng-container>
                                            <mat-header-row
                                                *matHeaderRowDef="PheDuyetService.displayColumns"></mat-header-row>
                                            <mat-row
                                                *matRowDef="let row; columns: PheDuyetService.displayColumns;"></mat-row>
                                            <mat-footer-row *matFooterRowDef="['loading']"
                                                [ngClass]="{'hide':PheDuyetDataSource!=null}"></mat-footer-row>
                                            <mat-footer-row *matFooterRowDef="['noData']"
                                                [ngClass]="{'hide':!(PheDuyetDataSource!=null && PheDuyetDataSource.data.length==0)}">
                                            </mat-footer-row>
                                        </mat-table>
                                        <mat-paginator #PheDuyetPaginator="matPaginator"
                                            [pageSizeOptions]="[10,20,50,100]" [pageSize]="10"
                                            [showFirstLastButtons]></mat-paginator>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<br />
<br />
<app-loading *ngIf="isShowLoading"></app-loading>