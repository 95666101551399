import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ThanhVienPhanQuyenUngDung } from 'src/app/shared/ThanhVienPhanQuyenUngDung.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class ThanhVienPhanQuyenUngDungService extends BaseService{
    displayColumns: string[] = ['DanhMucUngDungID', 'Active'];
    list: ThanhVienPhanQuyenUngDung[] | undefined;                  
    formData!: ThanhVienPhanQuyenUngDung;
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "ThanhVienPhanQuyenUngDung";
    }
}

