import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienPhanQuyenUngDung } from 'src/app/shared/ThanhVienPhanQuyenUngDung.model';
import { ThanhVienPhanQuyenUngDungService } from 'src/app/shared/ThanhVienPhanQuyenUngDung.service';

@Component({
  selector: 'app-thanh-vien-phan-quyen-ung-dung',
  templateUrl: './thanh-vien-phan-quyen-ung-dung.component.html',
  styleUrls: ['./thanh-vien-phan-quyen-ung-dung.component.css']
})
export class ThanhVienPhanQuyenUngDungComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  searchStringThanhVien: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;
  ThanhVienPhanQuyenUngDungIsSelectAll: boolean = false;

  constructor(
    public ThanhVienService: ThanhVienService,
    public ThanhVienPhanQuyenUngDungService: ThanhVienPhanQuyenUngDungService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienGetAllToListAsync();
    this.GetByParentIDToListAsync();
  }

  ThanhVienGetAllToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienService.GetByAllOrSearchStringToListAsync(this.searchStringThanhVien).subscribe(
      res => {
        this.ThanhVienService.list = res as ThanhVien[];
        this.ThanhVienService.list = this.ThanhVienService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetByParentIDToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienPhanQuyenUngDungService.GetByParentIDToListAsync(this.parentID).subscribe(
      res => {
        this.ThanhVienPhanQuyenUngDungService.list = res as ThanhVienPhanQuyenUngDung[];
        this.dataSource = new MatTableDataSource(this.ThanhVienPhanQuyenUngDungService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetByParentIDToListAsync();
    }
  }
  onThanhVienPhanQuyenUngDungIsSelectAllChange() {
    for (let i = 0; i < this.ThanhVienPhanQuyenUngDungService.list.length; i++) {
      this.ThanhVienPhanQuyenUngDungService.list[i].Active = this.ThanhVienPhanQuyenUngDungIsSelectAll;
      this.onThanhVienPhanQuyenUngDungActiveChange(this.ThanhVienPhanQuyenUngDungService.list[i], 1);
    }
    this.GetByParentIDToListAsync();
  }
  onThanhVienPhanQuyenUngDungActiveChange(element: ThanhVienPhanQuyenUngDung, listCase: number) {
    this.ThanhVienPhanQuyenUngDungService.SaveAsync(element).subscribe(
      res => {
        if (listCase == 0) {
          this.GetByParentIDToListAsync();
        }
      },
      err => {
        this.NotificationService.warn(environment.SaveNotSuccess);
      }
    );
  }
  onThanhVienFilter(searchString: string) {
    this.searchStringThanhVien = searchString;
    this.ThanhVienGetAllToListAsync();
  }
}