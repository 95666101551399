import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { CoSoLuuTruPhong } from 'src/app/shared/CoSoLuuTruPhong.model';
import { BaseService } from './Base.service';
@Injectable({
    providedIn: 'root'
})
export class CoSoLuuTruPhongService extends BaseService{
    displayColumns: string[] = ['Name', 'Note', 'GiaThapNhat', 'GiaCaoNhat', 'Active', 'Save']; 
    constructor(public httpClient: HttpClient) {
        super(httpClient);
        this.controller = "CoSoLuuTruPhong";
    }
}

