import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { ThanhVien } from 'src/app/shared/ThanhVien.model';
import { ThanhVienService } from 'src/app/shared/ThanhVien.service';
import { ThanhVienToken } from 'src/app/shared/ThanhVienToken.model';
import { ThanhVienTokenService } from 'src/app/shared/ThanhVienToken.service';

@Component({
  selector: 'app-thanh-vien-token',
  templateUrl: './thanh-vien-token.component.html',
  styleUrls: ['./thanh-vien-token.component.css']
})
export class ThanhVienTokenComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  searchStringThanhVien: string = environment.InitializationString;
  parentID: number = environment.InitializationNumber;
  ThanhVienTokenIsSelectAll: boolean = false;

  constructor(
    public ThanhVienService: ThanhVienService,
    public ThanhVienTokenService: ThanhVienTokenService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.ThanhVienGetAllToListAsync();
    this.GetByParentIDToListAsync();
  }

  ThanhVienGetAllToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienService.GetByAllOrSearchStringToListAsync(this.searchStringThanhVien).subscribe(
      res => {
        this.ThanhVienService.list = res as ThanhVien[];
        this.ThanhVienService.list = this.ThanhVienService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }

  GetByParentIDToListAsync() {
    this.isShowLoading = true;
    this.ThanhVienTokenService.GetByParentIDToListAsync(this.parentID).subscribe(
      res => {
        this.ThanhVienTokenService.list = res as ThanhVienToken[];
        this.dataSource = new MatTableDataSource(this.ThanhVienTokenService.list.sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1)));
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetByParentIDToListAsync();
    }
  } 
  onThanhVienFilter(searchString: string) {
    this.searchStringThanhVien = searchString;
    this.ThanhVienGetAllToListAsync();
  }
}