<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <div class="col s12 m12 l9">
        <h5 class="card-title"><b>Online booking</b></h5>
    </div>
    <div class="col s12 m12 l3">
        <button title="Save changes" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
        <a title="Close" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
            <i class="material-icons">close</i>
        </a>
    </div>
    <input name="ID" [(ngModel)]="DatPhongTrucTuyenService.formData.ID" type="hidden">
    <div class="col s12 m12 l6">
        <div class="col s12 m12 l4">
            <div class="input-field">
                <label for="BatDau">Check in</label>
            </div>
        </div>
        <div class="col s12 m12 l8">
            <div class="input-field">
                <input name="BatDau" style="text-align: right; font-weight: bold; width: 100%;"
                    [ngModel]="DatPhongTrucTuyenService.formData.BatDau | date:'yyyy-MM-dd'"
                    (ngModelChange)="onChangeBatDau($event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date">
            </div>
        </div>
        <div class="col s12 m12 l4">
            <div class="input-field">
                <label for="KetThuc">Check out</label>
            </div>
        </div>
        <div class="col s12 m12 l8">
            <div class="input-field">
                <input name="KetThuc" style="text-align: right; font-weight: bold; width: 100%;"
                    [ngModel]="DatPhongTrucTuyenService.formData.KetThuc | date:'yyyy-MM-dd'"
                    (ngModelChange)="onChangeKetThuc($event)" [ngModelOptions]="{ updateOn: 'blur' }" type="date">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Note">Note</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Note" name="Note" [(ngModel)]="DatPhongTrucTuyenService.formData.Note" type="text">
            </div>
        </div>
    </div>
    <div class="col s12 m12 l6">
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="CoSoLuuTruID">Unit</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select
                style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="CoSoLuuTruID" class="browser-default"
                [(ngModel)]="DatPhongTrucTuyenService.formData.CoSoLuuTruID">
                <input (keyup)="onFilterCoSoLuuTru($event.target.value)">
                <mat-option *ngFor="let item of CoSoLuuTruService.listSearch" [value]="item.ID">
                    {{item.TenDangKyKinhDoanh}}
                </mat-option>
            </mat-select>
            <br />
            <br />
        </div>
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="CoSoLuuTruPhongID">Category</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select
                style="padding-top:30px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="CoSoLuuTruPhongID" class="browser-default"
                [(ngModel)]="DatPhongTrucTuyenService.formData.CoSoLuuTruPhongID"
                (selectionChange)="CoSoLuuTruPhongGetAllToListAsync()">
                <mat-option *ngFor="let item of CoSoLuuTruPhongService.list" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
            <br />
            <br />
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="SoNguoi">People</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input style="text-align: right;" placeholder="0" name="SoNguoi"
                    [(ngModel)]="DatPhongTrucTuyenService.formData.SoNguoi" type="number">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="SoPhong">Room</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input style="text-align: right;" placeholder="0" name="SoPhong"
                    [(ngModel)]="DatPhongTrucTuyenService.formData.SoPhong" type="number">
            </div>
        </div>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>