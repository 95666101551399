import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/Notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { DiaDiemDaChieu } from 'src/app/shared/DiaDiemDaChieu.model';
import { DiaDiemDaChieuService } from 'src/app/shared/DiaDiemDaChieu.service';
import { DanhMucQuanHuyen } from 'src/app/shared/DanhMucQuanHuyen.model';
import { DanhMucQuanHuyenService } from 'src/app/shared/DanhMucQuanHuyen.service';

@Component({
  selector: 'app-dia-diem-da-chieu',
  templateUrl: './dia-diem-da-chieu.component.html',
  styleUrls: ['./dia-diem-da-chieu.component.css']
})
export class DiaDiemDaChieuComponent implements OnInit {

  dataSource: MatTableDataSource<any>;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  isShowLoading: boolean = false;
  searchString: string = environment.InitializationString;
  quanHuyenID: number = environment.InitializationNumber;

  constructor(
    public DanhMucQuanHuyenService: DanhMucQuanHuyenService,
    public DiaDiemDaChieuService: DiaDiemDaChieuService,
    public NotificationService: NotificationService,
  ) { }

  ngOnInit(): void {
    this.onSearch();
  }



  GetToListAsync() {
    this.isShowLoading = true;
    this.DiaDiemDaChieuService.GetByActiveToListAsync(true).subscribe(
      res => {
        this.DiaDiemDaChieuService.list = (res as DiaDiemDaChieu[]).sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
        this.dataSource = new MatTableDataSource(this.DiaDiemDaChieuService.list);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
        this.isShowLoading = false;
      },
      err => {
        this.isShowLoading = false;
      }
    );
  }
  onSearch() {
    if (this.searchString.length > 0) {
      this.dataSource.filter = this.searchString.toLowerCase();
    }
    else {
      this.GetToListAsync();
    }
  }
}